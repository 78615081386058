import './App.css';
import React, {useState, useRef, useEffect, Component } from 'react';
import { Provider } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import * as defs from './util/definitions';
import './firebase';
import { analytics } from './firebase';
import {logEvent, setDefaultEventParameters} from 'firebase/analytics';

import {dict} from './data/loadDict.js';
import store from './data/store.js';
import {getTGame, decrementUndos, checkStateVersion, pushMove, popMove, sendTileToPile, 
  setWholeState, selectTile, setTile, setTiles, moveTile, setSelected, setScore, setGameover, setFauxGame} from './data/tileSlice';
import {getCurrentGame, getGame, logGameResults, logStartGame, getPracticeGames, checkAndLogHighScore, updateHighScoreName} from './data/gameDB';
import {Emphasis, GoldMedal, SilverMedal, BronzeMedal, CorrectMedal, RightArrow, Button, CloseX, checkBuild, checkGame, mobileCheck, writeFeedback, obfuscate, deObfuscate} from './utility';
import {tutorialScript, TUTORIAL_DONE, TUTORIAL_DIALOG, SCRIPT_TILE, SCRIPT_PLAY, SCRIPT_DONE, SCRIPT_UNDO, SCRIPT_DELAY} from './tutorialScript';
import {isValidBoardState, scoreMove} from './util/wordUtils';
import {PieChart} from 'react-minimal-pie-chart';
import ReactTooltip from 'react-tooltip';


var ls = require('local-storage');

function initializeGame(gameId, game) {
  let tiles = new Array(defs.gridSize + defs.pileSize);

  //grid first, put special tiles here
  for ( let i=0 ; i<defs.gridSize ; i++ ) {
    // decoration?
    let letterm = 1;
    if ( game.multipliers ) {
      letterm = Number(game.multipliers.charAt(i));
    }
    tiles[i] = {'letterm': letterm, 'letter': false, 'word': false};
  }

  // tile pile
  let x=0;
  for ( let i=defs.gridSize ; i<defs.gridSize+defs.pileSize ; i++ ) {
    tiles[i] = {'letterm': 0, 'letter': game.tiles.charAt(x++)};
  }

  let gameState = {
    selected: -1,
    score: 0,
    goalScore: game.score,
    game: gameId,
    start: (new Date()).getTime(),
    tiles: tiles,
    moves: [],
    movesv2: [],
    undos: defs.NUM_UNDOS,
    gameover: false,
    fauxgame: false,
  };

  return gameState;
}



function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

function TileBasic({gridWidth, word, content, score, selected, scoreMod}) {
  let s = "tileNotSelected";
  if ( selected )
    s = "tileSelected";
  else if (word)
    s = "tileWord";

  return (
    <svg className={s} viewBox="0 0 60 60" width={gridWidth.current} height={gridWidth.current}>
      <rect width="100%" height="100%" strokeWidth="2" />

      <line x1="58" y1="59" x2="58" y2="2" stroke="lightgrey" strokeWidth="2" />
      <line x1="58" y1="2" x2="1" y2="2" stroke="lightgrey" strokeWidth="2" />

      <line x1="2" y1="2" x2="2" y2="59" stroke="darkgrey" strokeWidth="2" />
      <line x1="1" y1="58" x2="58" y2="58" stroke="darkgrey" strokeWidth="2" />
      <text x="50%" y="50%" stroke="rgba(0,0,0,0)" dominantBaseline="middle" textAnchor="middle" fontFamily="Arial, Helvetica, sans-serif" fontSize="36" fill="black" fontWeight="bold">{content}</text>
      <text x="5" y="55" stroke="rgba(0,0,0,0)" fontFamily="Arial, Helvetica, sans-serif" fontSize="12" fill="black" fontWeight="bold">{score}</text>
      <text x="40" y="15" stroke="rgba(0,0,0,0)" fontFamily="Arial, Helvetica, sans-serif" fontSize="12" fill="black" fontWeight="bold">{scoreMod}</text>
    </svg>
  );
}

function Tile({pile, gridWidth, onDragged, dragged, id, g, disabled, showEmphasis}) {
  const me = g.tiles[Number(id)];
  const dispatch = useDispatch();
  const [toucher, setToucher] = useState(-1);
  const [touchDelta, setTouchDelta] = useState({top:0, left:0});
  const touchHolder = useRef(null);


  const dragOver = (e) => {
    e.preventDefault();
  };
 
  const drop = (e) => {
    e.preventDefault();
    if ( Number(dragged) != Number(id) ) {
      if ( !g.fauxgame ) {
        moveTile(dispatch, g.tiles, Number(dragged), Number(id));
        dispatch(setSelected(-1));
      }
    }
  }

  const dragStart = (e, id, allow) => {
    if ( toucher != -1 )
      return;
    if ( g.fauxgame ) {
      e.preventDefault();
      return;   // prevents stuff happening during a Play sequence
    }

    if ( !allow ) {
      e.preventDefault();
      return;
    }

    onDragged(id);
    return true;
  };

  const clickTile = (e) => {
    e.preventDefault();
    if ( g.fauxgame ) {
      return;   // prevents stuff happening during a Play sequence
    }
    selectTile(dispatch, g.tiles, g.selected, Number(e.currentTarget.id));
  }

  const dblclickTile = (e) => {
    e.preventDefault();
    if ( g.fauxgame )
      return;   // prevents stuff happening during a Play sequence
    if (pile)
      return;
    sendTileToPile(dispatch, g.tiles, Number(e.currentTarget.id));
    dispatch(setSelected(-1));
  }

  const touchStart = (e) => {
    console.log("touchstart", g.fauxgame);
    if ( g.fauxgame ) {
      return;   // prevents stuff happening during a Play sequence
    }

    setToucher(e.currentTarget.id);
    let rect = e.currentTarget.children[0].getBoundingClientRect();
    setTouchDelta({left: rect.left+rect.width/2, top: rect.top+rect.height/2});
  }

  const touchEnd = (e) => {
    if ( toucher == -1 ) 
      return;
    if ( touchHolder.current ) {
      e.currentTarget.removeChild(touchHolder.current);
      touchHolder.current = null;
    }

    if ( toucher != -1 ) {
      // find the first element with an id
      let elements = document.elementsFromPoint(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
      let foundId = -1;
      for ( let i=0 ; i<elements.length ; i++ ) {
        if ( elements[i].id ) {
          foundId = elements[i].id;
        }
        else {
          // is the parent of one with an id the right class?
          if ( foundId != -1 && (elements[i].className == "gameGrid" || elements[i].className == "pileGrid") ) {
            // found a valid destination
            if ( Number(foundId) != Number(toucher) && !g.tiles[Number(foundId)].word ) {
              moveTile(dispatch, g.tiles, Number(toucher), Number(foundId));
              if ( g.selected == Number(toucher) )
                dispatch(setSelected(Number(foundId)));
            }
            break;
          }
          foundId = -1;
        }
      }
      e.currentTarget.children[0].style.removeProperty('position');
      e.currentTarget.children[0].style.removeProperty('top');
      e.currentTarget.children[0].style.removeProperty('left');
      e.currentTarget.children[0].style.removeProperty('transform');
  
      setToucher(-1);
      dispatch(setSelected(-1));
    }
  }
  const touchMove = (e) => {
    if ( toucher == -1 ) {
      return;
    }

    if ( !touchHolder.current ) {
      touchHolder.current = e.currentTarget.children[0].cloneNode(true);
      touchHolder.current.id = "mover" + e.currentTarget.children[0].id;
      e.currentTarget.appendChild(touchHolder.current);
    }

    //e.preventDefault(); 
    e.currentTarget.children[0].style.position = "absolute";
    e.currentTarget.children[0].style.zIndex = 999;
    e.currentTarget.children[0].style.transform = "scale(1.25)";

    e.currentTarget.children[0].style.top = (e.changedTouches[0].clientY - touchDelta.top);
    e.currentTarget.children[0].style.left = (e.changedTouches[0].clientX - touchDelta.left);

  }

  const touchCancel = (e) => {
    if ( toucher == -1 ) {
      return;
    }
    if ( touchHolder.current ) {
      e.currentTarget.removeChild(touchHolder.current);
      touchHolder.current = null;
    }

    e.currentTarget.children[0].style.removeProperty('position');
    e.currentTarget.children[0].style.removeProperty('top');
    e.currentTarget.children[0].style.removeProperty('left');
    e.currentTarget.children[0].style.removeProperty('transform');

    setToucher(-1);
    dispatch(setSelected(-1));
  }

  let content = me ? me.letter:'';
  let score = defs.letterScores[content];
  let scoreMod = "";
  if ( me && me.letter && !me.word && me.letterm && me.letterm > 1) {
    score *= me.letterm;
    scoreMod = me.letterm + "x";
  }

  if ( me && me.letter && !me.word && !disabled ) {
    return (
      <div className="tile" style={{width:gridWidth.current, height:gridWidth.current, position:"relative", touchAction:"none"}} id={id} onTouchStart={touchStart} onTouchMove={touchMove} onTouchEnd={touchEnd} onTouchCancel={touchCancel} 
            draggable="true" onDragStart={(e) => dragStart(e, id, true)} onDragOver={(e) => dragOver(e)} onDrop={drop} onClick={(e) => clickTile(e)} onDoubleClick={(e) => dblclickTile(e)}>
        <TileBasic gridWidth={gridWidth} word={false} content={content} score={score} scoreMod={scoreMod} selected={g.selected == Number(id)}/>
        <Emphasis show={showEmphasis("tile"+id)}/>
      </div>
    );
  }
  else if ( (me && me.word) || (me && disabled && (me.word || me.letter)) ) {
    return (
      <div style={{position:"relative", width:gridWidth.current, height:gridWidth.current, touchAction:"none"}} id={id} draggable onDragStart={(e) => e.preventDefault()} onClick={(e) => e.preventDefault()}>
        <TileBasic gridWidth={gridWidth} selected={false} word={me.word ? true:false} content={content} score={score} scoreMod={scoreMod} />
        <Emphasis show={showEmphasis("tile"+id)}/>
      </div>
    );
  }
  else {
    if ( id == defs.startLocation ) {
      if ( g.goalScore == 0 )
        return null; 
      return (
        <div style={{width:gridWidth.current, height:gridWidth.current, touchAction:"none"}} id={id} draggable onDragStart={(e) => dragStart(e, id, false)} onDragOver={(e) => dragOver(e)} onDrop={drop} onClick={(e) => clickTile(e)}>
          <svg width={gridWidth.current} height={gridWidth.current} viewBox="0 0 60 60">
            <rect width="100%" height="100%" stroke="grey" strokeWidth="2" fill="rgb( 230, 225, 230)" />
            <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontFamily="Arial, Helvetica, sans-serif" fontSize="18px" stroke="rgb(17,100, 117)" strokeWidth="3" 
              paintOrder="stroke" fill="rgb( 236, 236, 236)" fontWeight="bold">Start</text>
          </svg>
          <Emphasis show={showEmphasis("tile"+id)}/>
        </div>
      );
    }
    else {
      let textFill = "white", text = "";
      if ( me && me.letterm == 2 ) {
        textFill = "#647511";
        text = "2X";
      }
      else if ( me && me.letterm == 3 ) {
        textFill = "#641175";
        text = "3X";
      }
      return (
        <div style={{position:"relative", width:gridWidth.current, height:gridWidth.current, touchAction:"none"}} id={id} draggable onDragStart={(e) => dragStart(e, id, false)} onDragOver={(e) => dragOver(e)} onDrop={drop} onClick={(e) => clickTile(e)}>
          <svg viewBox="0 0 60 60" width={gridWidth.current} height={gridWidth.current} >
            <rect width="100%" height="100%" stroke="grey" strokeWidth="2" fill="rgb( 230, 225, 230)" />
            <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontFamily="Arial, Helvetica, sans-serif" fontSize="18px" stroke={textFill} strokeWidth="3" 
              paintOrder="stroke" fill="rgb( 236, 236, 236)" fontWeight="bold">{text}</text>
          </svg>
          <Emphasis show={showEmphasis("tile"+id)}/>
        </div>
      );
    }
  }
}

function Welcome(parentProps) {
  let props = parentProps.props;
  const [instructions, showInstructions] = useState(true);

  return (
    <div style={{marginTop:"20px", marginLeft:"10px", marginRight:"10px"}}>
    Welcome to Tiddle<br/><br/>
    <div style={{height:"510px", fontSize:"16px", textAlign:"left", fontWeight:"normal"}}>
    The goal of Tiddle is to earn medals by playing high scoring words on the game grid.  Tiddle can be challenging at first, but if you keep trying and watch high scoring games (after you finish), you'll get it!<br/><br/><span style={{fontSize:"12px", fontWeight:"bold"}}>* I recommend launching the tutorial if you're new to Tiddle</span><br/><br/>
    <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>

    <div style={{textAlign:"center", display:"inline-grid", gap:"25px 10px", gridTemplateColumns: "repeat(2, 1fr)"}}>
      Launch the interactive Tutorial
      <Button onClick={() => {props.showPopup(false); props.showTutorial(true);}}>Tutorial</Button>
      Read the Tiddle instructions
      <Button onClick={() => {props.showInstructions(true);}}>Instructions</Button>
      Learn how to score big on Tiddle
      <Button onClick={() => {props.showStrategy(true);}}>Strategy</Button>
      {false && 
        <>
        Play practice games
        <Button onClick={() => {props.showPracticePopup(true);}}>Practice</Button>
        </>
      }
      Email feedback to the Tiddle developer (thanks!)
      <Button onClick={() => {props.showPopup(false); window.open("mailto:tiddleus@gmail.com");}}>Feedback</Button>
      Terms of use and privacy policy
      <Button onClick={() => {props.showTermsPopup(true);}}>Terms & Privacy</Button>

    </div>

    </div>
    </div>
    </div>
  );
}

function Instructions(parentProps) {
  let props = parentProps.props;
  const [instructions, showInstructions] = useState(true);

  return (
    <div style={{marginTop:"20px", marginLeft:"10px", marginRight:"10px"}}>
    Welcome to Tiddle<br/><br/>
    <div style={{height:"475px", textAlign:"left", fontWeight:"normal"}}>

    <b>Tiddle Instructions</b><br/><br/>
    &bull; Move tiles to create words on the upper game grid by dragging and dropping or by selecting a tile and then selecting the destination location.<br/><br/>
    &bull; Hit the <b>Play</b> button to commit a move. Tiddle will validate the word(s) in a dictionary.<br/><br/>
    &bull; Hit <b>Undo</b> if you want to discard your last <b>Play</b>.<br/><br/>
    &bull; The <b>2X</b> and <b>3X</b> grid locations will multiply the score of tiles played on them.  The enhanced score only applies when the tile is first played.<br/><br/>
    &bull; <b>Play</b> tiles in one row or column making use of at least one tile already on the board and forming a contiguous word together.  Scores are tallied for all new words created.<br/><br/>
    &bull; Hit the <b>Done</b> button when you've achieved the highest score you can.  You may not need to use all the tiles.<br/><br/>
    &bull; Use the <b>"Start"</b> square in your first word, which must be 2 or more tiles.<br/><br/>
    <span style={{position:"absolute", top: 8, right: 5, cursor: "pointer"}} onClick={(e) => {e.preventDefault(); props.showPopup(false);}}><CloseX /></span>
    <br/>
    </div>

    </div>
  );
}

function Strategy(parentProps) {
  let props = parentProps.props;
  let gridWidth = {current:40};

  return (
    <div style={{marginTop:"20px", marginLeft:"10px", marginRight:"10px"}}>
    Tiddle Strategy<br/><br/>
    <div style={{textAlign:"left", fontWeight:"normal"}}>
    <b>Strategy</b><br/>
    Maximize your Tiddle score by using high-value letters in as many words as possible.  Look for opportunities to start with a short word with a high-value letter and then add to it to create more words.<br/><br/>
    <b>Example</b>: play the tiles 'a', 'e', 's' and 'x' like this:
    </div>
    <div style={{display:"inline-grid", marginTop:"10px", marginBottom:"15px", gridTemplateColumns: "repeat(2, 1fr)"}}>
    <div>1) play a & x, +9 points</div><div><TileBasic gridWidth={gridWidth} selected={false} word={false} content="a" score="1"/><TileBasic gridWidth={gridWidth} selected={false} word={false} content="x" score="8"/></div>
    <div>2) add e, +10 points</div><div><TileBasic gridWidth={gridWidth} selected={false} word={true} content="a" score="1"/><TileBasic gridWidth={gridWidth} selected={false} word={true} content="x" score="8"/><TileBasic gridWidth={gridWidth} selected={false} word={false} content="e" score="1"/></div>
    <div>3) add s, +11 points</div><div><TileBasic gridWidth={gridWidth} selected={false} word={true} content="a" score="1"/><TileBasic gridWidth={gridWidth} selected={false} word={true} content="x" score="8"/><TileBasic gridWidth={gridWidth} selected={false} word={true} content="e" score="1"/><TileBasic gridWidth={gridWidth} selected={false} word={false} content="s" score="1"/></div>
    <div>Total: 30 points</div><div></div>
    </div>
    </div>
  );    
}


function Practice(parentProps) {
  const [practiceGames, setPracticeGames] = useState([]);
  const [selected, setSelected] = useState('');
  let props = parentProps.props;

  function loadPracticeGame(game) {
    getGame(game, (g) => {
      console.log('practice_game_start', game);
      let gameState = initializeGame(game, g);
      gameState.fauxgame = true;
      props.dispatch(setWholeState(gameState));
      props.showPopup(false);
    });
  }
  
  useEffect(() => {
    if ( practiceGames.length == 0 ) {
      getPracticeGames((games) => {
        setPracticeGames(games);
        setSelected(games[0]);
      });
    }
  }, [practiceGames]);

  return (
    <div style={{display:"flex", flexDirection:"column", marginBottom: "10px", marginTop:"20px", marginLeft:"10px", marginRight:"10px", width:"95%", fontSize:"16px"}}>
      <div style={{paddingLeft:"25px", paddingRight:"25px"}}>Choose a practice game to play.  Play as many times as you like.<br/><br/></div>
      <div style={{display:"flex"}}>
      <div style={{textAlign:"left", paddingLeft:"20px", marginBottom:"20px", marginRight:"20px"}}>
        <select style={{textAlign:"center", width:"200px", fontSize:"18px"}} onChange={(e) => {setSelected(e.target.value);}} value={selected} name="games" id="games" size="10">
      {practiceGames.map(game => (
        <option key={game} value={game}>Game {game}</option>
      ))}
      </select>
      </div>
      <div><Button disabled={selected == ""} onClick={()=>{loadPracticeGame(selected);}}>Play</Button></div>
      </div>
    </div>
  );
}

function Terms(parentProps) {
  let props = parentProps.props;

  return (
    <div style={{marginTop:"20px", marginLeft:"10px", marginRight:"10px"}}>
    Tiddle Terms & Privacy<br/><br/>
    <div style={{textAlign:"left", fontWeight:"normal"}}>
    <b>Terms</b><br/>
    Thank you for playing Tiddle.  By accessing and using this website, you agree to the following terms.<br/>
    <li>Tiddle does not collect any personal data from players.</li>
    <li>Tiddle is provided for entertainment purposes only. </li>
    <li>Tiddle is not responsible for any errors or interruptions in gameplay. </li>
    <br/><b>Privacy</b><br/>
        Tiddle does not collect any personal data from users. This includes information such as names, addresses, phone numbers, or email addresses.  Cookies and Local Storage are used to store game state and an optional identifier for high scores.
      <br/><br/>
    </div>
    </div>
  );
}

function StatsShare(parentProps) {
  const [gameStats, setGameStats] = useState(null);
  const [feedback, showFeedback] = useState(false);
  const [toast, showToast] = useState(false);
  const [name, setName] = useState(null);
  const [highScores, setHighScores] = useState(null);
  const [selectedHS, setSelectedHS] = useState(-1);
  const scoreLevel = useRef(0);
  let props = parentProps.props;
  const [congratulations, setCongratulations] = useState("New Tiddle games are released every day.");
  const [isHighScore, setIsHighSore] = useState(false);

  function fetchHighScores(eligible) {
    let score = null;
    let moves = null;
    if ( eligible ) {
      score = scoreLevel.current > defs.LOSER_LEVEL ? props.g.score:0;
      moves = encodeMoves(props.g.tiles, props.g.moves);
    }

    checkAndLogHighScore(props.g.game, props.highScoreIdentifier.current, ls.get("name"), score, moves, 
    (hs) => {
      setHighScores(hs);
      for ( let i=0 ; i<hs.length ; i++ ) {
        if ( hs[i].id == props.highScoreIdentifier.current ) {
          setIsHighSore(true);
          setCongratulations("Congratulations! You earned a high score today.");
        }
      }
    });
  }

  useEffect(() => {
    if ( !gameStats ) {
      scoreLevel.current = defs.scoreLevel(props.g.goalScore, props.g.score);

      if ( props.show == defs.STATSSHARE_GAMEOVER ) {
        if ( !props.g.gameover && !props.g.fauxgame /*&& !props.localhost*/ ) {
          console.log("logging game results");
          let duration = Math.floor(((new Date()).getTime() - props.g.start) / 1000);
          logGameResults(props.g.game, props.g.goalScore, props.g.score, duration);
          fetchHighScores(true);
        }
        else if ( !props.g.fauxgame ) {
          // just fetch the highscores
          fetchHighScores(false);
        }
        props.dispatch(setGameover(true));
      }
      else if ( !props.g.fauxgame ) {
        // just fetch the highscores
        fetchHighScores(false);
      }

      let gs = ls.get("gamestats");
      if ( !gs && props.g.gameover ) {
        gs = {goal0: 0, goal1: 0, goal2: 0, goal3:0};
      }
      setGameStats(gs);
      let n = ls.get("name");
      if ( n != null )
        setName(decodeURIComponent(n));
    }
  }, [gameStats]);

  function showHighScores() {
    if (!highScores || highScores.length == 0) {
      return (<span>No scores yet</span>);
    }
    let content = [];
    let medal, name;
    let lastScore = 0;
    let scoreOrdinal = 0;
    for (let i=0 ; i<highScores.length ; i++) {
      let sl = defs.scoreLevel(props.g.goalScore, highScores[i].score);
      switch (sl) {
        case defs.GOLD_LEVEL:
          medal = (<GoldMedal style={{width:"25px", height:"25px"}} />);
          break;
        case defs.SILVER_LEVEL:
          medal = (<SilverMedal style={{width:"25px", height:"25px"}} />);
          break;
        case defs.BRONZE_LEVEL:
          medal = (<BronzeMedal style={{width:"25px", height:"25px"}} />);
          break;
        default:
          medal = (<div style={{width:"25px"}}/>);
      }
      name = highScores[i].name ? highScores[i].name:"";

      if ( highScores[i].score != lastScore ) {
        lastScore = highScores[i].score;
        scoreOrdinal++;
      }
      
      let selected = {};
      if ( i == selectedHS )
        selected = {border:"1px solid #ECA142", borderRadius:"4px", background:"lightgrey"}

      let self = "";
      if ( highScores[i].id == props.highScoreIdentifier.current )
        self = <span style={{color:"red"}}>✱&nbsp;</span>;

      content.push(<div id={i} onDrag={(e) => e.stopPropagation()} 
            onDoubleClick={(e)=>{
              e.preventDefault(); e.stopPropagation(); 
              if ( props.g.gameover ) {
                props.showPopup(false); 
                props.scriptToRun.current = defs.SCRIPT_HIGHSCORE; 
                props.highScoreScript.current = highScores[Number(e.currentTarget.id)];
                props.setReplayRunning(true);
              }
            }} 
          onClick={(e) => {e.stopPropagation(); e.preventDefault(); setSelectedHS(e.currentTarget.id);}} 
          style={{userSelect:"none", cursor:"pointer", height:"25px", display:"flex", gap:"10px", marginBottom:"4px", alignItems:"center", ...selected}} 
          key={i}><div style={{userSelect: "none", fontWeight:"bold", width:"25px"}}>{self}{highScores[i].score}</div>{medal}<div style={{userSelect: "none",}}>{name}</div></div>);
    }
    return content;
  }

  let data = [];
  if ( gameStats ) {
    if ( "goal0" in gameStats && gameStats["goal0"] != 0 )
      data.push({title: "No medal", value: gameStats["goal0"], color: '#515148' });
    if ( "goal1" in gameStats && gameStats["goal1"] != 0 )
      data.push({title: "Bronze", value: gameStats["goal1"], color: 'brown' });
    if ( "goal2" in gameStats && gameStats["goal2"] != 0 )
      data.push({title: "Silver", value: gameStats["goal2"], color: 'silver' });
    if ( "goal3" in gameStats && gameStats["goal3"] != 0 )
      data.push({title: "Gold", value: gameStats["goal3"], color: 'gold' });
  }

let sl = defs.scoreLevel(props.g.goalScore, props.g.score);
let medal;
if ( props.g.gameover ) {
    if ( sl != defs.LOSER_LEVEL ) {
      switch ( sl ) {
        case defs.BRONZE_LEVEL:
          medal = "Bronze";
          break;
        case defs.SILVER_LEVEL:
          medal = "Silver"
          break;
        case defs.GOLD_LEVEL:
          medal = "Gold";
          break;
      }
    }
  }

  let numGames = ls.get("numGames");
  if ( !numGames )
    numGames = 0;

  //https://www.npmjs.com/package/react-minimal-pie-chart
  let gridColumns = "1fr 2px 1fr";
  let gridGap = "10px 10px";
  if ( !props.g.gameover ) {
    gridColumns = "1fr";
    gridGap = "";
  }
  return (
    <div style={{marginTop:"20px", marginLeft:"10px", marginRight:"10px"}}>
  
      <Popup kind="feedback" showPopup={showFeedback} show={feedback} game={props.g.game} />
      {props.g.gameover && 
      <>
        <span style={{fontSize:"16px"}}>Game Over</span><br/><br/>
        <span style={{fontWeight:"normal"}}>
        Your score on {props.g.fauxgame ? <>the practice</>:<>today's</>} game was {props.g.score}, which {sl == defs.LOSER_LEVEL ? ("wasn't enough to earn a medal") : ((props.g.fauxgame ? "would have ":" ") + "earned you a " + medal + " medal ")}{sl > 0 && <CorrectMedal style={{width:"20px", height:"20px"}} medal={sl}/>}.<br/><br/>
        <span style={{fontWeight:"bold"}}>{congratulations}</span><br/><br/>
        </span>
      </>
      }
    {gameStats ?
    <>
      <span style={{fontWeight:"bold"}}>Medals Won</span><br/>
      <div style={{marginTop:"5px", marginBottom:"5px", display:"flex"}}>
        <div style={{marginLeft:"60px", marginRight:"50px", display:"inline-grid", gap:"10px", gridTemplateColumns:"20px 20px"}}>
          <GoldMedal style={{width:"25", height:"25"}} /><span style={{marginTop:"7px"}}>{gameStats["goal3"]}</span>
          <SilverMedal style={{width:"25", height:"25"}} /><span style={{marginTop:"7px"}}>{gameStats["goal2"]}</span>
          <BronzeMedal style={{width:"25", height:"25"}} /><span style={{marginTop:"7px"}}>{gameStats["goal1"]}</span>
        </div>
      <div style={{width:"100px", height:"100px"}}>
      <PieChart
        data={data}
        radius={50}
        labelPosition={62}
        segmentsStyle={{ transition: 'stroke .3s'}}
        animate
        nameKey="name"
        center={[50, 50]}
        viewBoxSize={[100,100]}
      />
      </div>
      </div>
      Total Games: {numGames}
      </>
    :
    <>
    <br/><br/>Play Tiddle and come back to check your stats.<br/><br/><br/>
    </>
    }
  <br/><br/>
  <div style={{width:"100%", height:"2px", background:"black", marginBottom:"10px"}}/>
  {(props.g.gameover && !props.g.fauxgame) &&
    <div><label>Name </label><input type="text" value={name ? name:""} onChange={(e) => {
      setName(e.target.value);
      let n = e.target.value;
      if ( n && n.length>0 ) {
        n = encodeURIComponent(n);
        ls.set("name", n);
      }
      else 
        ls.remove("name");
      if ( isHighScore )
        updateHighScoreName(props.g.game, props.highScoreIdentifier.current, e.target.value, (hs) => setHighScores(hs))
    }} id="name" name="name" size="20" maxLength="10" placeholder="Optional for sharing" /></div>
  }

  <div style={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:"1 px", marginBottom:"10px"}}>
      <div style={{display:"inline-grid", fontSize:"14px", fontWeight:"normal", gap:gridGap, gridTemplateColumns: gridColumns}}>
        <div style={{display:"flex", flexDirection:"column", gap:"5px"}}>
        {(props.g.gameover && !props.g.fauxgame) &&
          <>
          <span style={{fontWeight:"bold", fontSize:"12px"}}>Share & Watch Games</span>
          <Button onClick={() => {
                logResults(props.g.game);
                if ( shareResults(props.g, encodeURIComponent(name ? name:"")) )
                  showToast(true);
                setTimeout(() => {
                    showToast(false);
                }, 3000);
             }}>Share Your Results
             <div style={{display:toast? "inline":"none"}} className="toast">copied to clipboard</div>
             </Button>

          </>  
        }
        {props.g.gameover &&
          <>
          <Button onClick={()=>{props.showPopup(false); props.scriptToRun.current = defs.SCRIPT_GOLD; props.setReplayRunning(true);}}>Watch Gold Medal</Button>
          <Button onClick={()=>{
              if ( !props.sharedGame.current ) {
                  props.setError("noshare");
              }
              else {
                props.showPopup(false); 
                props.scriptToRun.current = defs.SCRIPT_SHARED; 
                props.setReplayRunning(true);}
              }
            }>Watch Shared</Button>
            Double tap to watch high score game
          
          </>
        }
          </div>
          {props.g.gameover && 
            <div style={{background:"black", width:"2px"}}/>
          }
          <div style={{overflowY:"scroll", height:"150px", display:"flex", flexDirection:"column", padding:"3px"}}>
            <div style={{display:"flex", fontSize:"12px", fontWeight:"bold"}}>Game {props.g.game} High Scores</div>
              {showHighScores()}
            </div>
      </div>

  </div>
</div>
  );
}

function Error(parentProps) {
  let props = parentProps.props;

  if ( props.error == "noshare" ) {
    return(
      <div style={{marginTop:"20px", marginLeft:"10px", marginBottom:"20px", marginRight:"10px"}}>
      Watch Shared Game<br/><br/>
      <div style={{textAlign:"left", fontWeight:"normal"}}>Click on a game that has been shared with you to see that user's solution.  
        <br/><br/>Sharing your game will allow others to see your solution after they've completed the day's game.</div>
      </div>
    );
  }
  else if ( props.error == "launchfromshared") {
    // is the share from today's game or not?
    if ( props.sharedGame.current && props.sharedGame.current.game != props.g.game ) {
      return(
        <div style={{marginTop:"20px", marginLeft:"10px", marginBottom:"20px", marginRight:"10px"}}>
        Watch Shared Game<br/><br/>
        <div style={{textAlign:"left", fontWeight:"normal"}}>
          You can Watch the previous day's game that {props.sharedGame.current.name ? props.sharedGame.current.name : "was"} shared with you now if you want.  You can also watch it after you've finished today's game.
          <div style={{marginTop:"10px", display:"flex", justifyContent:"center"}}><Button onClick={() => {
                props.showPopup(false); 
                props.scriptToRun.current = defs.SCRIPT_SHARED; 
                props.setReplayRunning(true);
          }} >Watch Now</Button></div>
          </div>
        </div>
      );
    }
    else {
      return(
        <div style={{marginTop:"20px", marginLeft:"10px", marginBottom:"20px", marginRight:"10px"}}>
        Watch Shared Game<br/><br/>
        <div style={{textAlign:"left", fontWeight:"normal"}}>After you finish today's puzzle, choose the "Shared Game" button to see the game that {props.sharedGame.current.name ? props.sharedGame.current.name : "was"} shared with you.</div>
        </div>
      );
    }
  }
  else if ( props.error == defs.TODAY_ERROR) {
    return(
      <div style={{marginTop:"20px", marginLeft:"10px", marginBottom:"20px", marginRight:"10px"}}>
      Tiddle Message<br/><br/>
      <span style={{textAlign:"left", fontWeight:"normal"}}>{defs.TODAY_MESSAGE}</span>
      </div>
    );
  }
  else {
    return (
      <div style={{marginTop:"20px", marginLeft:"10px", marginBottom:"20px", marginRight:"10px"}}>
      Can't play that move.<br/><br/>
      {props.error == 'start' &&
          <span style={{textAlign:"left", fontWeight:"normal"}}>The first word must be at least 2 letters and include the center tile.</span>
      }
      {props.error == 'row' &&
          <span style={{textAlign:"left", fontWeight:"normal"}}>All played tiles must be in the same row or column and must form a word together</span>
      }
      {props.error == 'badwords' &&
          <span style={{textAlign:"left", fontWeight:"normal"}}>All words must be in the dictionary</span>
      }
      </div>
    );
  }
}

function GameFeedback(parentProps) {
  let props = parentProps.props;

  function submitForm() {
    let feedback = {likey: -1, feedback:null, email: null, game:props.game};
    let radios = document.getElementsByName('rating');
    for (var i = 0, length = radios.length; i < length; i++) {
      if (radios[i].checked) {
        feedback.likey = radios[i].value;
        break;
      }
    }
    feedback.feedback = document.getElementById("feedback").value
    feedback.email = document.getElementById("email").value
    writeFeedback(feedback);
  }

  return (
    <div style={{marginTop:"20px", marginLeft:"20px", marginRight:"20px"}}>
    On a scale of 1 (not so fun) to 5 (super fun), how did you like today's game?<br/><br/>
    <div id="checkboxes">
      <div className="checkboxgroup">    
        <input type="radio" id="1" name="rating" value="1"/>
        <label>1</label>
      </div>
      <div className="checkboxgroup">
        <input type="radio" id="2" name="rating" value="2"/>
        <label>2</label>
      </div>
      <div className="checkboxgroup">
        <input type="radio" id="3" name="rating" value="3"/>
        <label>3</label>
      </div>
      <div className="checkboxgroup">
        <input type="radio" id="4" name="rating" value="4"/>
        <label>4</label>
      </div>
      <div className="checkboxgroup">
        <input type="radio" id="5" name="rating" value="5"/>
        <label>5</label>
      </div>
    </div>
    <br/><br/>
    Other Feedback:<br/>
    <textarea id="feedback" name="feedback" rows="3" cols="35"></textarea><br/><br/>  
    Your email (optional):<br/>
    <input type="text" id="email" name="email"/><br/><br/>
    <div style={{display:"flex", justifyContent:"space-around", marginBottom: "10px", fontWeight:"bold"}}>
    <Button onClick={()=>{submitForm(); props.showPopup(false);}}>Submit</Button>
    </div>
    </div>
  );
}

function ShowToast(parentProps) {
  let props = parentProps.props;
  const [toast, showToast] = useState(true);

  useEffect(() => {
    if ( toast ) {
      const timer = setTimeout(() => {
        showToast(false);
        props.showPopup(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
 }, [toast]);

 return (
  <div >
    {props.message}
  </div>
 );

}

function AreYouSure(parentProps) {
    const [checked, setChecked] = useState(false);
    const [show, setShow] = useState(false);
    let props = parentProps.props;

    useEffect(() => {
      if ( !show ) {
        let s = ls.get("NoConfirmDone") != "1";
        setShow(s);
        if ( !s ) {
          props.showPopup(false); 
          props.showStatsSharePopup(defs.STATSSHARE_GAMEOVER);
        }
      }
  }, [show]);

  function saveState() {
    // save the checked state if the user doesn't want to see this again
    if ( checked )
      ls.set("NoConfirmDone", 1);
  }

  return (
    <div style={{marginTop:"35px", marginLeft:"10px", marginBottom:"20px", marginRight:"10px"}}>
      Are you sure you're done playing today's Tiddle?<br/><br/>
      <div style={{display:"flex", justifyContent:"center", gap:"20px", marginBottom:"10px", marginTop:"10px"}}>
      <Button onClick={()=>{saveState(); props.showPopup(false); props.showStatsSharePopup(defs.STATSSHARE_GAMEOVER);}}>Yes</Button><Button onClick={()=>{saveState(); props.showPopup(false);}}>No</Button>
      </div>
      <label><input type="checkbox" checked={checked} onChange={() => setChecked(!checked)} />Don't show this again</label>
    </div>  
  );  
}

// common props
// kind = which popup
// showPopup - function to show/hide a popup (used when user closes popup)
// show - should the popup be shown
function Popup(props) {

  if ( !props.show ) {
    return null;
  }
  if ( props.kind == "toast" ) {
    return (
      <div style={{left: "50%", top:"200px", marginLeft:"-60px", display:props.show ? "inline":"none", position: "absolute", zIndex:900, touchAction:"none"}}>
      <div style={{ display:"flex", justifyContent:"space-around", alignItems:"center", fontSize: "14px", fontWeight:"bold", position:"relative", 
      height: "auto", width:"120px", border:"3px solid black", borderRadius:"10px", background:"white"}}>
      <ShowToast props={props} />
      </div>
      </div>
    );
  }
  else if (props.kind == "error" || props.kind == "feedback" || props.kind == "areyousure" ) {
    if ( props.kind == "error" && (props.error == "newuser_play" || props.error == "newuser_done")) {
      return (
        <div className="modal" style={{display:props.show ? "inline":"none", zIndex:902}} onClick={(e) => {e.preventDefault(); props.showPopup(false);}}>
        <div className="modalContent" style={{top:"335px", width:"200px", left:"calc(50% + 100px)"}} onClick={(e) => {e.stopPropagation();}}>
        <span style={{position:"absolute", top: 8, right: 5, cursor: "pointer"}} onClick={(e) => {e.preventDefault(); props.showPopup(false);}}><CloseX /></span>
        <div style={{marginTop:"20px", marginLeft:"10px", marginBottom:"20px", marginRight:"10px"}}>
        Tiddle Message<br/><br/>
        <span style={{textAlign:"left", fontWeight:"normal"}}>{props.error == "newuser_play" ? "Remember to hit the Play button when you've made a word.":"Hit the Done button when you can't play any more words."}</span>
        </div>
        </div>
        </div>
      );
    }
    return (
      <div className="modal" style={{display:props.show ? "inline":"none", zIndex:902}} onClick={(e) => {e.preventDefault(); props.showPopup(false);}}>
      <div className="modalContent" style={{top:"150px"}} onClick={(e) => {e.stopPropagation();}}>
      <span style={{position:"absolute", top: 8, right: 5, cursor: "pointer"}} onClick={(e) => {e.preventDefault(); props.showPopup(false);}}><CloseX /></span>

      {props.kind == "error" && 
        <Error props={props} />
      }
      {props.kind == "feedback" && 
        <GameFeedback props={props} />
      }
      {props.kind == "areyousure" &&
        <AreYouSure props={props} />
      }
      </div>
      </div>
    );
  }
  else {
    return (
      <div className="modal" style={{display:props.show ? "inline":"none"}} onClick={(e) => {e.preventDefault(); props.showPopup(false);}}>
      <div className="modalContent" onClick={(e) => {e.stopPropagation();}}>
      <span style={{position:"absolute", top: 8, right: 5, cursor: "pointer"}} onClick={(e) => {e.preventDefault(); props.showPopup(false);}}><CloseX /></span>
        {props.kind == "welcome" && 
          <Welcome props={props} />
        }
        {props.kind == "instructions" && 
          <Instructions props={props} />
        }
        {props.kind == "strategy" && 
          <Strategy props={props} />
        }
        {props.kind == "statsshare" && 
          <StatsShare props={props} />
        }
        {props.kind == "practice" && 
          <Practice props={props} />
        }
        {props.kind == "terms" &&
          <Terms props={props} />
        }

      </div>
      </div>
    );
  }
}

function Score({g, showEmphasis}) {
  const [oldLevel, setOldLevel] = useState(defs.LOSER_LEVEL);
  const [celebrate, setCelebrate] = useState(false);
  
  let opacities = [1, 1, 1];
  if ( g.goalScore > 0 ) {
    let scoreLevel = defs.scoreLevel(g.goalScore, g.score);
    if (scoreLevel >= defs.BRONZE_LEVEL )
      opacities[0] = 0.25;
    if (scoreLevel >= defs.SILVER_LEVEL )
      opacities[1] = 0.25;
    if ( scoreLevel >= defs.GOLD_LEVEL )
      opacities[2] = 0.25;

    if ( oldLevel != scoreLevel && !g.gameover ) {
      // need to celebrate
      if ( scoreLevel > oldLevel ) {
        setCelebrate(defs.scoreLevel(g.goalScore, g.score));
        delay(3000).then(() => {setCelebrate(false);});
      }
      setOldLevel(scoreLevel);
    }
  }

  return (
    <div className="scoreContainerWrapper">
    <div className="scoreContainer" >
        <div className="score">Score<div style={{display:"flex"}}><span>{g.score}</span><CorrectMedal style={{width:"27px", height:"27px"}} medal={defs.scoreLevel(g.goalScore, g.score)} />
        </div><Emphasis show={showEmphasis("playScore")}/></div>
        {g.goalScore > 0 ?
          <div className="scoreMedal">Scores for Medals<br/><span>
            <div style={{display:"flex"}}>
            <span style={{opacity:opacities[0], marginLeft:"5px"}}>{defs.getScoreLevelScore(g.goalScore, defs.BRONZE_LEVEL)}</span><BronzeMedal style={{width:"27px", height:"27px", opacity:opacities[0]}} />
            <span style={{opacity:opacities[1], marginLeft:"5px"}}>{defs.getScoreLevelScore(g.goalScore, defs.SILVER_LEVEL)}</span><SilverMedal style={{width:"27px", height:"27px", opacity:opacities[1]}} />
            <span style={{opacity:opacities[2], marginLeft:"5px"}}>{defs.getScoreLevelScore(g.goalScore, defs.GOLD_LEVEL)}</span><GoldMedal style={{width:"27px", height:"27px", opacity:opacities[2]}} />
            </div>
          </span><Emphasis show={showEmphasis("playScoreMedal")}/></div>
        :
          <div className="scoreMedal"><span></span></div>
      }
      <div style={{display: celebrate?'inline':'none'}} className="celebrate" ><CorrectMedal medal={celebrate} /></div>
    </div>
    </div>
  );
}

function colToLetter(c) {
  switch(c) {
      case 0:
          return 'A';
      case 1:
          return 'B';
      case 2:
          return 'C';
      case 3:
          return 'D';
      case 4:
          return 'E';
  }
}

function fillGaps(vert, start, end)
{
  let i = start;
  let ret = "";
  while ( i < end ) {
    if ( vert )
      i += defs.gridDimensions;
    else
      i++;
    if ( i < end )
      ret += ".";
  }
  return ret;
}

function encodeMoves(tiles, moves) {
  let s = "";
  let vert;
  let first = true;
  for ( let i=0 ; i<moves.length ; i++ ) {
    if ( !first )
      s += ",";
    first = false;
    vert = false;
    if ( moves[i].length >= 2 && Math.floor(moves[i][0]/defs.gridDimensions) != Math.floor(moves[i][1]/defs.gridDimensions) )
      vert = true;
    s += vert ? String.fromCharCode('A'.charCodeAt(0) + moves[i][0]) : String.fromCharCode('a'.charCodeAt(0) + moves[i][0]);
    for ( let j=0 ; j<moves[i].length ; j++ ) {
      if ( j > 0 ) {
        // fill in any gaps
        s += fillGaps(vert, moves[i][j-1], moves[i][j]);
      }
      s += tiles[moves[i][j]].letter;
    }
  }

  return s;
}

function shareResults(g, name) {
  let url = "https://tiddle.us/shareGame?";

  let s = encodeMoves(g.tiles, g.moves);
  
  url += "w=" + obfuscate(s) + "&g=" + g.game + "&s=" + g.score + "&l=" + defs.scoreLevel(g.goalScore, g.score);

  // name is already URI encoded
  if (name)
    url += "&n=" + name;

  if (navigator.share) {
    navigator.share({
      title: 'Tiddle',
      url: url
    }).then(() => {
      console.log('Thanks for sharing!');
    })
    .catch(console.error);
    return false;
  } else {
    navigator.clipboard.writeText(url);
  }  
  return true;
}

function logResults(game) {
  logEvent(analytics, 'game_shared', {game: game});
  console.log('game_shared', game);
}


function formatScoreBrag(score) {
  return "Score " + score + " points";
}

function scorePotentialMove(tiles, moves) {
  if ( !tiles || tiles.length == 0 )
    return 0;

  // gather up all the non-word tiles on the grid and make them into a move
  let move = [];
  for ( let i=0 ; i<defs.gridSize ; i++ ) {
    if ( tiles[i].letter && !tiles[i].word )
      move.push(i);
  }
  if ( move.length == 0 )
    return 0;

  // first move needs to be 2+ letters
  if ( moves.length == 0 && move.length < 2 ) {
    return 0;
  }

  // the first move must include the middle piece
  if ( moves.length == 0 && !tiles[defs.startLocation].letter) {
    return 0;
  }

  // is this a valid move?
  let result = isValidBoardState(tiles, move, dict, (s) => {return 0;});
  if ( !result ) {
    return false;
  }
  let moveScore = scoreMove(tiles, move, dict);
  
  return moveScore;
}

function createMove(dispatch, fauxgame, tiles, score, moves, setUndoEnabled, setPlayEnabled, error, showToast) {
  // gather up all the non-word tiles on the grid and make them into a move
  let move = [];
  for ( let i=0 ; i<defs.gridSize ; i++ ) {
    if ( tiles[i].letter && !tiles[i].word )
      move.push(i);
  }
  if ( move.length == 0 )
    return false;

  // first move needs to be 2+ letters
  if ( moves.length == 0 && move.length < 2 ) {
    error("start");
    return false;
  }

  // the first move must include the middle piece
  if ( moves.length == 0 && !tiles[defs.startLocation].letter) {
    error("start");
    return false;
  }

  // is this a valid move?
  let result = isValidBoardState(tiles, move, dict, error);
  if ( !result ) {
    return false;
  }
  // turn off undo while we go through this process
  setUndoEnabled(false);

  let moveScore = scoreMove(tiles, move, dict);
  showToast(formatScoreBrag(moveScore));
  if ( !fauxgame)
    dispatch(setFauxGame(true));

  delay(1000).then(() => {

    // save it
    let j=1;
    for ( let i=0 ; i<defs.gridSize ; i++ ) {
      if ( result[i].word != tiles[i].word ) {
        delay(100*j++).then(() => {dispatch(setTile({index: i, tile: result[i]}))});
      }
    }
    delay(100*j++).then(() => { 
      if ( !fauxgame )
        dispatch(setFauxGame(false));
      setUndoEnabled(true); 
      setPlayEnabled(true);
      dispatch(pushMove(move));
      dispatch(setScore(moveScore + score));
      dispatch(setSelected(-1));
    });
  
  });
  return true;
}

function undoMove(dispatch, tiles, moves, score, showToast) {
  // create a working array
  let working = new Array(defs.gridSize + defs.pileSize);
  for ( let i=0 ; i<defs.gridSize + defs.pileSize ; i++ ) {
    working[i] = {...tiles[i]};
  }

  // throw all the non used tiles back in the pile
  let gotOne = false;
  for ( let grid=0 ; grid<defs.gridSize ; grid++ ) {
    if ( !working[grid].word && working[grid].letter ) {
      for ( let i=defs.gridSize ; i<defs.gridSize + defs.pileSize ; i++ ) {
        if ( !working[i].letter ) {
          working[i].letter = working[grid].letter;
          working[grid].letter = false;
          gotOne = true;
          break;
        }
      }
    }
  }

  if ( moves.length > 0 && !gotOne) {

    let move = moves[moves.length-1];
    let moveScore = scoreMove(working, move, dict);
    showToast("Undo");

    // throw the last move back into the grid
    for ( let grid=0 ; grid<defs.gridSize ; grid++ ) {
      if ( move.includes(grid) ) {
        for ( let i=defs.gridSize ; i<defs.gridSize + defs.pileSize ; i++ ) {
          if ( !working[i].letter ) {
            working[i].letter = working[grid].letter;
            working[grid].letter = false;
            working[grid].word = false;
            break;
          }
        }
      }
    }
    dispatch(setScore(score - moveScore));
    dispatch(popMove());
  }

  dispatch(setSelected(-1));
  dispatch(setTiles(working));
}

function PlayRow(props) {
  const [undoEnabled, setUndoEnabled] = useState(true);
  const [playEnabled, setPlayEnabled] = useState(true);

  let undoFill = "black";
  let undoClick = () => undoMove(props.dispatch, props.g.tiles, props.g.moves, props.g.score, (msg) => props.showToast(msg));
  if ( !undoEnabled || props.g.undos == 0 || props.g.gameover ) {
    undoFill = "#666666";
    undoClick = () => {};
  }
  let doneFill = "black";
  let doneClick = () => {props.showAreYouSure(true);};
  if ( props.g.moves.length == 0 || props.g.gameover ) {
    doneFill = "#666666";
    doneClick = () => {};
  }

  let playFill = "black";
  let playClick = () => {
    if ( !playEnabled )
      return;
    setPlayEnabled(false);
    if ( !createMove(props.dispatch, props.g.fauxgame, props.g.tiles, props.g.score, props.g.moves, setUndoEnabled, setPlayEnabled,
        (error) => {props.setError(error)}, (score) => props.showToast(score)) )
          setPlayEnabled(true);
  }
  let potentialScore = scorePotentialMove(props.g.tiles, props.g.moves);
  let potentialScoreText = "";
  if ( potentialScore > 0 ) {
    potentialScoreText = " for " + potentialScore;
  }
  if ( props.g.gameover ) {
      playFill = "#666666";
      playClick = () => {};
    }

  function tilePileTouched() {
    if ( !props.g.tiles || props.g.tiles.length == 0 )
      return false;
    for ( let i=defs.gridSize; i<defs.gridSize+defs.pileSize ; i++ ) {
      if ( !props.g.tiles[i].letter )
        return true;
    }
    return false;
  }

  // tell a new user about the Play button
  if ( !props.g.fauxgame ) {
    if ( props.newUser.current  == 1 && props.g.moves.length == 0 && tilePileTouched() ) {
      delay(1000).then(() => {props.setError("newuser_play");});
      props.newUser.current = 2;
    }
    else if ( props.newUser.current == 2 && props.g.moves.length >= 1 ) {
      delay(1000).then(() => {props.setError("newuser_done");});
      props.newUser.current = false;
    }
  }

  let buttonSize = (2*props.gridWidth.current)/3;

  return (
    <div id="playrow" style={{display:"flex", justifyContent:"center", position:"relative"}}>

    <div className="play" >
      <div data-for="maintt" data-tip="Undo last move" onClick={undoClick} style={{margin:"3px auto", width:buttonSize, height:buttonSize}}>
        <svg className="playbutton" width="100%" height="100%" viewBox="0 0 30 30" >
          <rect width="100%" height="100%" stroke="grey" strokeWidth="2" fill="rgb( 236, 236, 236)" />
          <polygon points="6,15 24,6 24,24" fill={undoFill}/>
        </svg>
        <Emphasis show={props.showEmphasis("undoButton")}/>
      </div>
      <span>Undo</span>
    </div>
    <div className="play" style={{width:buttonSize+35}}>
      <div data-for="maintt" data-tip="Play move" onClick={playClick} style={{margin:"3px auto", width:buttonSize, height:buttonSize}}>
        <svg className="playbutton" width="100%" height="100%" viewBox="0 0 30 30" >
          <rect width="100%" height="100%" stroke="grey" strokeWidth="2" fill="rgb( 236, 236, 236)" />
          <polygon points="6,6 24,15 6,24" fill={playFill}/>
        </svg>
        <Emphasis show={props.showEmphasis("playButton")}/>
      </div>
      <span>Play{potentialScoreText}</span>
    </div>
    <div className="play" >
      <div data-for="maintt" data-tip="Game over" onClick={doneClick} style={{margin:"3px auto", width:buttonSize, height:buttonSize}}>
        <svg className="playbutton" width="100%" height="100%" viewBox="0 0 30 30" >
          <rect width="100%" height="100%" stroke="grey" strokeWidth="2" fill="rgb( 236, 236, 236)" />
          <polygon points="6,6 24,6 24,24 6,24" fill={doneFill}/>
        </svg>
        <Emphasis show={props.showEmphasis("doneButton")}/>
      </div>
      <span>Done</span>
    </div>
    </div>
  );
}

function Information({showWelcome, disable}) {
  
  function doWelcome(e) {
    showWelcome(true);
    e.preventDefault();
  }

  return(
    <div data-for="maintt" data-tip="Instructions" onClick={(e) => {if ( !disable) doWelcome(e);}}>
      <svg style={{marginRight: "12px"}} version="1.1" x="0px" y="0px" viewBox="0 0 330 330" width="30" height="30">
        <path fill="white" d="M165,0C74.019,0,0,74.02,0,165.001C0,255.982,74.019,330,165,330s165-74.018,165-164.999C330,74.02,255.981,0,165,0z
          M165,300c-74.44,0-135-60.56-135-134.999C30,90.562,90.56,30,165,30s135,60.562,135,135.001C300,239.44,239.439,300,165,300z"/>
        <path fill="white" d="M164.998,70c-11.026,0-19.996,8.976-19.996,20.009c0,11.023,8.97,19.991,19.996,19.991
          c11.026,0,19.996-8.968,19.996-19.991C184.994,78.976,176.024,70,164.998,70z"/>
        <path fill="white" d="M165,140c-8.284,0-15,6.716-15,15v90c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15v-90C180,146.716,173.284,140,165,140z"/>
      </svg>
    </div>
  );
}

function Stats({showStatsSharePopup, disable}) {
  function doStats(e) {
    showStatsSharePopup(defs.STATSSHARE_TITLEBAR);
    e.preventDefault();
  }

  return (
    <div data-for="maintt" data-tip="Statistics & sharing" onClick={(e) => {if ( !disable ) doStats(e);}}>
      <svg version="1.1" x="0px" y="0px" viewBox="0 0 490.1 490.1" width="30" height="30">
			<path fill="white" d="M279.7,228.95h193.2c9.5,0,17.2-7.7,17.2-17.2c0-51.3-18.7-100.8-52.7-139.2c-39.9-45.2-97.4-71.1-157.7-71.1
				c-9.5,0-17.1,7.7-17.1,17.1v193.2C262.5,221.25,270.2,228.95,279.7,228.95z M296.8,36.55c44.1,4.3,85.2,25.2,114.8,58.7
				c24.6,27.9,39.7,62.6,43.2,99.4h-158V36.55z"/>
			<path fill="white" d="M218.5,51.65C98,51.65,0,149.65,0,270.15s98,218.5,218.5,218.5s218.5-98,218.5-218.5c0-9.5-7.7-17.2-17.1-17.2H235.7
				V68.85C235.7,59.35,228,51.65,218.5,51.65z M401.9,287.35c-8.7,93.5-87.6,167-183.4,167c-101.6,0-184.2-82.6-184.2-184.2
				c0-95.8,73.5-174.7,167-183.4v183.4c0,9.5,7.7,17.1,17.1,17.1h183.5L401.9,287.35L401.9,287.35z"/>
      </svg>

    </div>
  );
}

// playback a replay of a game, either one from the AI or from a shared game
// if replay is false then load up the ai version
// replay share encoding plan:
// 3B983.D412.2A71 - grid location + list of indices into letters
//    shared game must be today's game that has been completed by the user
// script
// play tile - letter: <index of letter>
//              grid: <index into grid
// delay - number of ms to delay before next frame
// hit play - hit the play button, no args
// done - end of script, no args
// fixit - need to save the gameover state, restore the game to start and then restore the gameover state
function Replay({g, gridWidth, highScoreScript, showStatsSharePopup, sharedGame, replayRunning, setReplayRunning, showToast, dispatch, setError, scriptToRun}) {
  const [replayScript, setReplayScript] = useState([]);
  const [ranScript, setRanScript] = useState(false);
  const [frame, setFrame] = useState(0);
  const currentFrame = useRef(0);
  const savedState = useRef(null);
  const script = useRef([]);
  const stop = useRef(false);
  const watchedGame = useRef(0);
  
  function c2n(c) {
    switch (c) {
      case 'A':
        return 0;
      case 'B':
        return 1;
      case 'C':
        return 2;
      case 'D':
        return 3;
      case 'E':
        return 4;
    }
  }

  function movesToScript(moves, toScript) {
    // what version 
    let version = 1;
    if ( moves[0].l )
      version = 2;
    for ( let i=0  ; i<moves.length ; i++ ) {
      if ( version == 1 ) {
        // convert position to grid location
        // 3B - horizontal word on 3rd row, starting on 2nd column
        let horizontal = false;
        let next = 0;
        if ( +moves[i].position.charAt(0) ) {
          // horizontal word
          horizontal = true;
          next = (Number(moves[i].position.charAt(0))-1) * defs.gridDimensions + c2n(moves[i].position.charAt(1));
        }
        else {
          horizontal = false;
          next = (Number(moves[i].position.charAt(1))-1) * defs.gridDimensions + c2n(moves[i].position.charAt(0));
        }

        // place letters, skipping '.'
        for ( let j = 0 ; j<moves[i].tiles.length ; j++ ) {
          let l = String(moves[i].tiles[j]);
          if ( l != '.' ) {
            toScript({w:SCRIPT_TILE, t:l.toLowerCase(), g:next});
          }
          if ( horizontal )
            next++;
          else
            next += defs.gridDimensions;
        }
      }
      else {
        // version 2
        for ( let m=0 ; m<moves[i].m.length ; m++ ) {
          toScript({w:SCRIPT_TILE, t:moves[i].l.charAt(m).toLowerCase(), g:moves[i].m[m]});
        }
      }
      toScript({w:SCRIPT_PLAY});
    }
  }

  /*
    example shared game encoding
      Mgag,nip,ip
      M - capital indicates vertical, M - A  = 12
      gag - three letters played
      , - next move
      n - lower case indicates horizontal, n - a = 13
      ip - letters played
      i - i - a = 8
      p - letter played
  */

  function sharedToScript(moves, toScript) {
      let nextIsStartWord = true;
      let a = 'a'.charCodeAt(0);
      let A = 'A'.charCodeAt(0);
      let vert, word, wordPos;
      let first = true;
      for (let i = 0; i < sharedGame.current.moves.length; i++) {
          if ( nextIsStartWord ) {
            if ( !first )
              toScript({w:SCRIPT_PLAY});
            first = false;

            let c = sharedGame.current.moves.charCodeAt(i);
            if ( c >= a && c <= a + 26 ) {
                wordPos = c - a;
                vert = false;
            }
            else {
                wordPos = c - A;
                vert = true;
            }
            nextIsStartWord = false;
            word = "";
            continue;
          }
          if ( sharedGame.current.moves[i] == ',' ) {
              nextIsStartWord = true;
          }
          else {
            if ( sharedGame.current.moves[i] != '.' )
              toScript({w:SCRIPT_TILE, t:sharedGame.current.moves[i].toLowerCase(), g:wordPos});
            if ( vert )
              wordPos += 5;
            else
              wordPos++;
          }
      }
      toScript({w:SCRIPT_PLAY});
  }

  function startSharedScript() {
    if ( !sharedGame.current ) {
      setError("noshare");
      return;
    }
    watchedGame.current = sharedGame.current.game;
    logEvent(analytics, 'watch_shared', {game: sharedGame.current.game});
    startScript(sharedToScript);
  }

  function startGoldScript() {
    watchedGame.current = g.game;
    logEvent(analytics, 'watch_gold', {game: g.game});
    startScript(movesToScript);
  }

  function startHighScoreScript() {
    if ( !highScoreScript.current ) {
      setError("noshare");
      return;
    }
    watchedGame.current = g.game;
    logEvent(analytics, 'watch_highscore', {game: g.game});
    sharedGame.current = highScoreScript.current;
    highScoreScript.current = null;
    startScript(sharedToScript);
  }

  function startScript(generateScript) {
    // reload the game from db
    getGame(watchedGame.current, (game) => {
      // save the current state
      savedState.current = g;
      let gameState = initializeGame(watchedGame.current, game);
      gameState.fauxgame = true;
      dispatch(setWholeState(gameState));

      script.current = [{w: SCRIPT_DELAY, ms:1000}];
      generateScript(game.movesv2?game.movesv2:game.moves, (line) => {
        if ( line.w == SCRIPT_TILE ) {
          script.current.push(line);
          script.current.push({w:SCRIPT_DELAY, ms:1000});
        }
        else if ( line.w == SCRIPT_PLAY ) {
          script.current.push(line);
          script.current.push({w:SCRIPT_DELAY, ms:3000});
        }
      });
      script.current.push({w:SCRIPT_DONE});

      setReplayScript(script.current); 
      stop.current = false;
      currentFrame.current = -1; 
      setFrame(0);
      setRanScript(true);
    });
  }

  function resetReplayState() {
    setRanScript(false);  // tells UI not to show Stop/Done button anymore
    setReplayRunning(false);
    if ( savedState.current && savedState.current.gameover )
      showStatsSharePopup(defs.STATSSHARE_GAMEOVER);
    if ( savedState.current )
      dispatch(setWholeState(savedState.current));
    savedState.current = null;
  }

  function stopScript() {
    stop.current = true;

    // if we're currently running a script then cancel and let the script reset state when it's done
    // otherwise, go ahead and reset the state
    if ( frame == 0 )
      resetReplayState();
  }

  // play the current frame
  if ( frame != currentFrame.current && frame < replayScript.length) {
    currentFrame.current = frame;
    let frameAction = replayScript[frame].w;
    if ( stop.current )
      frameAction = SCRIPT_DONE;
    switch (frameAction) {
      case SCRIPT_DELAY:
        delay(replayScript[frame].ms).then(() => {setFrame(frame+1);});
        break;
      case SCRIPT_TILE:
        // find this tile in the pile
        let foundIt = false;  // break out of badly formed scripts
        for ( let i=defs.gridSize ; i<defs.gridSize+defs.pileSize ; i++ ) {
          if ( g.tiles[i].letter == replayScript[frame].t ) {
            foundIt = true;
            delay(0).then(() => {if (!stop.current) moveTile(dispatch, g.tiles, i, replayScript[frame].g); setFrame(frame+1);});
            break;
          }
        }
        if ( !foundIt )
          delay(0).then(() => {setFrame(frame+1);});
        break;
      case SCRIPT_PLAY:
        delay(0).then(() => {if (!stop.current) {createMove(dispatch, g.fauxgame, g.tiles, g.score, g.moves, () => {}, () => {}, (error) => {}, (score) => showToast(score));} setFrame(frame+1);});
        break;
      case SCRIPT_DONE:
        setFrame(0);
        currentFrame.current = 0;
        // only reset if stop button caused the halt
        if ( stop.current ) {
          delay(0).then(() => {
            resetReplayState();
          });
        }
        break;
    }
  }

  // are we kicking off a script?
  if ( scriptToRun.current ) {
    if ( scriptToRun.current == defs.SCRIPT_GOLD )
      startGoldScript();
    else if ( scriptToRun.current == defs.SCRIPT_SHARED )
      startSharedScript();
    else if ( scriptToRun.current == defs.SCRIPT_HIGHSCORE )
      startHighScoreScript();
    scriptToRun.current = false;
  }

  let e = document.getElementById("playrow");
  let height = 0;
  if ( e )
    height = (e.getBoundingClientRect().height - 20) + "px";

  return(
    <div style={{left: "calc(50% - 152.5px)", top:"0px", display:replayRunning ? "inline":"none", zIndex:2, position: "absolute", touchAction:"none"}}>
    <div style={{ display:"flex", justifyContent:"space-around", alignItems:"center", fontSize: "20px", 
      position:"relative", height:height, width:"305px", marginTop:"5px", border:"3px solid black", borderRadius:"10px", backgroundColor:"white"}}>
    <div style={{display:"flex"}}>
      <span style={{fontSize:"18px", marginTop:"3px", marginRight:"5px"}}>Game {watchedGame.current}</span>
      <div><svg style={{marginRight:"10px", marginTop:"3px", overflow: "visible", display:ranScript ? "inline":"none"}} version="1.1"  x="0px" y="0px" viewBox="0 0 423.754 423.754" width="25" height="25">
          <path d="M354.24,47.4l39.879-39.879H272.196v121.924l60.801-60.801c56,50.066,77.251,132.004,46.918,205.235
            c-18.585,44.869-53.531,79.815-98.4,98.4c-44.866,18.585-94.288,18.585-139.158,0c-44.869-18.585-79.815-53.531-98.4-98.4
            c-18.585-44.869-18.585-94.29,0-139.159l-27.717-11.48c-21.651,52.272-21.651,109.848,0,162.12
            c21.652,52.272,62.364,92.984,114.637,114.636c26.14,10.827,53.595,16.24,81.06,16.239c27.459-0.001,54.927-5.414,81.061-16.239
            c52.271-21.652,92.983-62.364,114.636-114.636C442.739,200.6,418.532,105.826,354.24,47.4z">
            {currentFrame.current > 0 &&
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              from="0 211.5 211.5"
              to="-360 211.5 211.5"
              dur="7s"
              repeatCount="indefinite" />
            }
            </path>
        </svg>
        </div>
        <div style={{cursor:"pointer", display:"flex", backgroundColor:"rgb( 230, 225, 230)", border: "1px solid black", borderRadius:"5px", padding:"3px 3px 3px 3px"}} onClick={()=>{stopScript();}}>
          <svg style={{marginLeft:"3px"}} version="1.1" x="0px" y="0px" viewBox="0 0 65.518 65.518" width="20" height="20">
            <path fill="red" d="M32.759,0C14.696,0,0,14.695,0,32.759s14.695,32.759,32.759,32.759s32.759-14.695,32.759-32.759S50.822,0,32.759,0z
              M6,32.759C6,18.004,18.004,6,32.759,6c6.648,0,12.734,2.443,17.419,6.472L12.472,50.178C8.443,45.493,6,39.407,6,32.759z
              M32.759,59.518c-5.948,0-11.447-1.953-15.895-5.248l37.405-37.405c3.295,4.448,5.248,9.947,5.248,15.895
              C59.518,47.514,47.514,59.518,32.759,59.518z"/>    
          </svg>
        {frame > 0 ?
          <div style={{marginLeft:"5px", marginRight:"2px", marginBottom:"2px"}}>Stop</div>
        :
          <div style={{marginLeft:"5px", marginRight:"2px", marginBottom:"2px"}}>Done</div>
        }
        </div>
      </div>
    </div>
    </div>
  );
}

function Tutorial({showTutorial, tutorial, g, dispatch, showToast, setGameover, setEmphasis}) {
  const [frame, setFrame] = useState(0);
  const savedState = useRef(null);
  const currentFrame = useRef(0);
  const tutorialGame = useRef(null);
  const pause = useRef(false);
  

  // reset?
  if ( !tutorial && pause.current )
    pause.current = false;

  // load the game
  if ( tutorial && !tutorialGame.current && !pause.current) {
    // load up the tutorial game
    tutorialGame.current = true;  // temporary state to stop multiple loads.
    getGame(defs.TUTORIAL_GAME, (game) => {
      // save the current state
      savedState.current = g;
      let gameState = initializeGame(defs.TUTORIAL_GAME, game);
      gameState.fauxgame = true;
      tutorialGame.current = gameState;
      dispatch(setWholeState(gameState));
      currentFrame.current = -1; 
      setFrame(0);
      logEvent(analytics, 'watch_tutorial', {game: tutorialGame.game});
    });
  }

  // play the script
  if ( frame != currentFrame.current && frame < tutorialScript.length) {
    currentFrame.current = frame;
    switch (tutorialScript[frame].w) {
      case SCRIPT_DELAY:
        delay(tutorialScript[frame].ms).then(() => {setFrame(frame+1);});
        break;
      case SCRIPT_TILE:
        // find this tile in the pile
        for ( let i=defs.gridSize ; i<defs.gridSize+defs.pileSize ; i++ ) {
          if ( g.tiles[i].letter == tutorialScript[frame].t ) {
            delay(0).then(() => {moveTile(dispatch, g.tiles, i, tutorialScript[frame].g); setFrame(frame+1);});
            break;
          }
        }
        break;
      case SCRIPT_UNDO:
        delay(0).then(() => {undoMove(dispatch, g.tiles, g.moves, g.score, (score) => showToast(score)); setFrame(frame+1);});
        break;
      case SCRIPT_PLAY:
        delay(0).then(() => {createMove(dispatch, g.fauxgame, g.tiles, g.score, g.moves, () => {}, () => {}, (error) => {}, (score) => showToast(score)); setFrame(frame+1);});
        break;
      case TUTORIAL_DONE:
        delay(0).then(() => {dispatch(setGameover(true)); setFrame(frame+1)});
        break;
      case TUTORIAL_DIALOG:
        delay(0).then(() => {setEmphasis(tutorialScript[frame].emphasis);});
        break;
      case SCRIPT_DONE:
        pause.current = true;
        setFrame(0);
        currentFrame.current = 0;
        delay(0).then(() => {
          showTutorial(false);
          if ( savedState.current )
            dispatch(setWholeState(savedState.current));
          savedState.current = null;
          tutorialGame.current = null;
        });
        break;
    }
  }

  if ( tutorial && tutorialGame.current && tutorialScript[frame].text ) {
    return (
      <div className="modal" style={{backgroundColor:"rgba(0,0,0,.2)", display:"inline"}}>
      <div className="modalContent" style={{height: "auto", top:tutorialScript[frame].y, left:"calc(50% - "+ tutorialScript[frame].x +")"}}>
      <div style={{}}/>
      <span style={{width:"100%", margin:"10px 10px 30px 10px"}}>{tutorialScript[frame].text}</span>
      <span style={{position:"absolute", bottom: 0, right: 5, cursor: "pointer"}} onClick={(e) => {e.preventDefault(); setFrame(frame+1);}}><RightArrow /></span>
      </div>
      </div>
    );
  }
  return null;
}

function Game() {
  const dispatch = useDispatch();
  const g = useSelector(getTGame);
  const [welcome, showWelcome] = useState(false);
  const [instructions, showInstructions] = useState(false);
  const [error, setError] = useState(false);
  const [toast, showToast] = useState(false);
  const [statsSharePopup, showStatsSharePopup] = useState(false);
  const [serverChange, setServerChange] = useState(false);
  const [draggedTile, setDraggedTile] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [practicePopup, showPracticePopup] = useState(false);
  const [termsPopup, showTermsPopup] = useState(false);
  const [replayRunning, setReplayRunning] = useState(false);
  const [strategy, showStrategy] = useState(false);
  const [tutorial, showTutorial] = useState(false);
  const [areYouSure, showAreYouSure] = useState(false);
  const [emphasis, setEmphasis] = useState(false);
  const localhost = useRef(false);
  const currentGameRef = useRef(0);
  const isMobile = useRef(false);
  const isTouch = useRef(false);
  const sharedGame = useRef(false);
  const scriptToRun = useRef(false);
  const newUser = useRef(false);
  const gridWidth = useRef(55);
  const highScoreIdentifier = useRef(0);
  const highScoreScript = useRef("");

  function showEmphasis(e) {
    if ( !emphasis )
      return false;
    return emphasis.includes(e);
  }

  if ( serverChange ) {
    setServerChange(false);
    window.location.reload(true);
  }
  useEffect(() => {
    // initialize state
    if ( !initialized ) {
      setInitialized(true);

      if ( window.location.host.indexOf("localhost") != -1 ) {
        localhost.current = true;
        console.log("running on localhost");
      }

      if ( !localhost.current ) {
        checkBuild(() => {
          window.location.reload(true);
        });
      }

      isMobile.current = mobileCheck();
      isTouch.current = 'ontouchstart' in window || navigator.msMaxTouchPoints;

      function handleResize() {
        const vw = Math.min(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        const vh = Math.min(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        //console.log(vw, vh);
       gridWidth.current = Math.max(Math.min(Math.floor(vh * 0.09), 90), 50);
      }
      //window.addEventListener('resize', handleResize)
      //window.addEventListener('orientationchange', handleResize)
      handleResize();

      const qp = new URLSearchParams(window.location.search);

      // do we have a high score identifier?
      highScoreIdentifier.current = ls.get("highscoreid");
      if ( !highScoreIdentifier.current ) {
        var uuid = window.crypto.randomUUID();
        highScoreIdentifier.current = uuid;
        ls.set("highscoreid", uuid);
      }

      // did a user share a game with us?
      if ( qp.get("g") ) {
        try {
          sharedGame.current = {moves: deObfuscate(qp.get("w")), name: qp.get("n"), game:qp.get("g")};
          logEvent(analytics, 'launch_shared', {game: sharedGame.current.game});
        }
        catch {
          sharedGame.current = false;
        }
      }

      if ( qp.get("theme") ) {
        document.documentElement.setAttribute('data-theme', qp.get("theme"));
      }

      getCurrentGame((currentGameId) => {
        let debug = qp.get("debug") == "1";

        currentGameRef.current = currentGameId;

        let recoveredState = ls.get("game");
        if ( qp.get("reset") == "1" && recoveredState ) {
          ls.remove("game");
          recoveredState = null;
        }
        if ( recoveredState && !checkStateVersion(recoveredState) ) {
          console.log("state changed");
          recoveredState = null;
          ls.remove("game");
        }
        if ( !debug && recoveredState && recoveredState.game == currentGameId ) {
          dispatch(setWholeState(recoveredState));
          if ( recoveredState.gameover )
            showStatsSharePopup(defs.STATSSHARE_GAMEOVER);
        }
        else {
          getGame(currentGameId, (game) => {
            if (defs.TODAY_MESSAGE.length > 0 && ls.get("message") != defs.TODAY_MESSAGE_NUMBER ) {
              setError(defs.TODAY_ERROR);
              ls.set("message", defs.TODAY_MESSAGE_NUMBER);
            }
            let numGames = ls.get("numGames");
            if ( !numGames )
              numGames = 0;
            numGames++;
            ls.set("numGames", numGames);
            if ( !localhost.current ) {
              logEvent(analytics, 'game_start', {game: currentGameId});
              logStartGame(currentGameId);
            }
            console.log('game_start', currentGameId);
            if ( sharedGame.current && ls.get("sharedGame") != sharedGame.current.game ) {
              setError("launchfromshared");
              ls.set("sharedGame", sharedGame.current.game);
            }

            // show welcome
            if ( !ls.get('showedwelcome') ) {
              ls.set('showedwelcome', 1);
              showWelcome(true);
              newUser.current = 1;
              if ( !localhost.current ) {
                logEvent(analytics, 'new_user', {game: currentGameId});

                // referral?
                if ( qp.get("r") ) {
                  if ( qp.get("r") == "fb" ) {
                    // facebook
                    logEvent(analytics, 'fb_referral', {game: currentGameId});
                  }
                }
              }
            }
            dispatch(setWholeState(initializeGame(currentGameId, game)));
            //let e = document.getElementById("turkey");
            let e = document.getElementById("monster");
            //let e = document.getElementById("halloween");
            if ( e ) {
              e.style.display = "inline-block";
              delay(3000).then(() => {
                e.style.display = "none";
              });
            }

          });
        }
      });
    }
    const interval = setInterval(() => {
      checkBuild(() => {
        setServerChange(true);
      });
      checkGame(currentGameRef.current, () => {
        setServerChange(true);
      });
    }, (1000 * 60 * 60 * 12));   // twice a day

    return () => clearInterval(interval);
  }, [initialized]);

  const getGridTiles = () => {
    let content = [];
    for ( let i=0 ; i<defs.gridSize ; i++ ) {
      content.push(<Tile g={g} showEmphasis={showEmphasis} gridWidth={gridWidth} pile={false} onDragged={setDraggedTile} dragged={draggedTile} disabled={g.gameover || replayRunning || tutorial} key={i} id={i}/>);
    }
    return content;
  }

  const getPileTiles = () => {
    let content = [];
    for ( let i=defs.gridSize ; i<defs.gridSize+defs.pileSize ; i++ ) {
      content.push(<Tile g={g} showEmphasis={showEmphasis} gridWidth={gridWidth} pile={true} onDragged={setDraggedTile} dragged={draggedTile} disabled={g.gameover || replayRunning || tutorial} key={i} id={i} />);
    }
    return content;
  }

  //https://github.com/ReactTooltip/react-tooltip
  return (

    <div className="App">
      <header className="App-header">
          <div style={{position:"absolute", left:"calc(50% - 180px)", display:"flex"}}>
            <Information showWelcome={showWelcome} disable={replayRunning ? true:false}/>
            <Stats showStatsSharePopup={showStatsSharePopup} disable={replayRunning ? true:false}/>
          </div>
          {tutorial ? 
            <p>Tutorial</p>
          :
            <p>{g.fauxgame ? "Watching":"Tiddle Game"} {g.game}</p>
          }
      </header>
      <div className="game-container" >
      <div style={{flex:1}}></div>
      <div className="main">
        <ReactTooltip id="maintt" className="tooltip" delayShow={1000} disable={isTouch.current?true:false} />
        <Popup kind="welcome" newUser={newUser} showPopup={showWelcome} show={welcome} showStrategy={showStrategy} showTutorial={showTutorial} showInstructions={showInstructions} showPracticePopup={showPracticePopup} showTermsPopup={showTermsPopup} />
        <Popup kind="strategy" showPopup={showStrategy} show={strategy} />
        <Popup kind="instructions" showPopup={showInstructions} show={instructions} />
        <Popup kind="terms" showPopup={showTermsPopup} show={termsPopup} />
        <Popup kind="error" showPopup={setError} show={error} error={error} sharedGame={sharedGame} scriptToRun={scriptToRun} g={g} setReplayRunning={setReplayRunning} />
        <Popup kind="toast" showPopup={showToast} show={toast} message={toast} />
        <Popup kind="statsshare" setError={setError} highScoreScript={highScoreScript} highScoreIdentifier={highScoreIdentifier} sharedGame={sharedGame} scriptToRun={scriptToRun} setReplayRunning={setReplayRunning} showPopup={showStatsSharePopup} show={statsSharePopup} g={g} dispatch={dispatch} localhost={localhost.current}/>
        <Popup kind="practice" showPopup={showPracticePopup} show={practicePopup} g={g} dispatch={dispatch}/>
        <Popup kind="areyousure" showPopup={showAreYouSure} show={areYouSure} showStatsSharePopup={showStatsSharePopup} g={g} dispatch={dispatch} />
        <Tutorial setEmphasis={setEmphasis} showTutorial={showTutorial} tutorial={tutorial} setReplayRunning={setReplayRunning} g={g} dispatch={dispatch} showToast={showToast} setGameover={setGameover} />
        <Score g={g} showEmphasis={showEmphasis} />
        <div className="gameGrid" style={{width:gridWidth.current * defs.gridDimensions}}>
            {getGridTiles()}
            {/* <div id="turkey" style={{display:"none", position:"absolute", width:"100%", height:"100%", animation: "celebrateFramesReverse 3s linear", opacity:0}}><img style={{width:"100%", height:"100%"}} src="/turkey-svgrepo-com.svg"/></div> */}
            <div id="monster" style={{display:"none", position:"absolute", width:"100%", height:"100%", animation: "celebrateFramesReverse 5s linear", opacity:0}}><img style={{width:"100%", height:"100%"}} src="/tiddle_monster.png"/></div>
            {/*<div id="halloween" style={{display:"none", position:"absolute", width:"100%", height:"100%", animation: "celebrateFramesReverse 5s linear", opacity:0}}><img style={{width:"100%", height:"100%"}} src="/turkey_letters.jpeg"/></div>*/}
            </div>
        <div style={{position:"relative"}}>
        <Replay g={g} gridWidth={gridWidth} highScoreScript={highScoreScript} showStatsSharePopup={showStatsSharePopup} scriptToRun={scriptToRun} dispatch={dispatch} sharedGame={sharedGame} replayRunning={replayRunning} setReplayRunning={setReplayRunning} showToast={showToast} setError={setError} tutorial={tutorial} />
        <PlayRow g={g} gridWidth={gridWidth} showEmphasis={showEmphasis} newUser={newUser} showAreYouSure={showAreYouSure} dispatch={dispatch} showToast={showToast} setError={setError} />
        </div>
        <div className="pileGrid" style={{width:gridWidth.current * defs.gridDimensions}}>
          {getPileTiles()}
        </div>
{/*
<img className="mover" style={{ left:"calc(50% - " + gridWidth.current*3 + "px)", animationDelay:"5s", animationDuration: "15s", width:"25px", height:"25px", position:"absolute", top:"0px"}} src="/snowflake-svgrepo-com.svg" />
<img className="mover" style={{ left:"calc(50% - " + gridWidth.current*3.5 + "px)", animationDelay:"2s", animationDuration: "15s", width:"30px", height:"30px", position:"absolute", top:"0px"}} src="/snowflake-svgrepo-com.svg" />
<img className="mover" style={{ left:"calc(50% - " + gridWidth.current + "px)", animationDelay:"3s", animationDuration: "10s", width:"21px", height:"21px", position:"absolute", top:"0px"}} src="/snowflake-svgrepo-com.svg" />
<img className="mover" style={{ left:"calc(50% + " + gridWidth.current*2.5 + "px)", animationDelay:"6s", animationDuration: "18s", width:"22px", height:"22px", position:"absolute", top:"0px"}} src="/snowflake-svgrepo-com.svg" />
<img className="mover" style={{ left:"calc(50% + " + gridWidth.current*3 + "px)", animationDelay:"1s", animationDuration: "10s", width:"31px", height:"31px", position:"absolute", top:"0px"}} src="/snowflake-svgrepo-com.svg" />
*/}
      </div>
      <div>

      </div>
      <div style={{flex:1}}></div>
    </div>
    </div>
  );
}

function App() {
  return (
    <Provider store = { store }>
      <Game />
    </Provider>
  );
}

export default App;
