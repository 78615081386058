import { doc, updateDoc, setDoc, getDoc, getDocs, getDocFromServer, increment, query, collection} from "firebase/firestore";
import {logEvent, setDefaultEventParameters} from 'firebase/analytics';

import {db, analytics} from '../firebase';
import * as defs from '../util/definitions';
var ls = require('local-storage');

/*
schema for game

game
    id: numerical identifier
    tiles: string of characters
    score: top score
*/

export function getCurrentGame(response) {
    // DEBUG check if the game id is passed as a query string
    const qp = new URLSearchParams(window.location.search);
    if ( qp.get("game") ) {
        response(qp.get("game"));
        return
    }
    const docRef = doc(db, "todaygame", "1");
    const docSnap = getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
            //console.log("Document data:", docSnap.data());
            let game = docSnap.data();
            response(game.game);
        } else {
            // doc.data() will be undefined in this case
            //console.log("No such document!");
        }
    });
}

export function getGame(game, response) {
    const docRef = doc(db, "games", String(game));
    const docSnap = getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
            //console.log("Document data:", docSnap.data());
            response(docSnap.data());
        } else {
            // doc.data() will be undefined in this case
            //console.log("No such document!");
        }
    });
}

export function logStartGame(game) {
    setDoc(doc(db, "gameStats", String(game)), {starts: increment(1)}, {merge:"true"}).then(() => {
    });
}

const maxHighScores = 10;
export function checkAndLogHighScore(game, identifier, name, score, encodedMoves, scoreResponse) {
    // fetch high scores
    let highScores = [];
    getGameResults(game, (stats) => {
        if (stats.highScores) {
            highScores = stats.highScores;
        }

        if ( score ) {
            let oldNumScores = 0;
            let lastScore = 0;
            for ( let i=0 ; i<highScores.length ; i++ ) {
                if ( highScores[i].score != lastScore ) {
                    oldNumScores++;
                    lastScore = highScores[i].score;
                }
            }

            //console.log(stats, stats.highScores.length);
            if ( oldNumScores <= maxHighScores || score >= highScores[highScores.length-1].score ) {
                console.log("new high score");
                // user got a high score
                highScores.push({score:score, name:name, game: game, moves: encodedMoves, id: identifier});
                highScores.sort((a, b) => {if (a.score > b.score) return -1; else return 1;});
                
                // limit number of high scores to a unique number of scores, not a unique number of entries
                let numScores = 0;
                lastScore = 0;
                for ( let i=0 ; i<highScores.length ; i++ ) {
                    if ( highScores[i].score != lastScore ) {
                        if ( numScores >= maxHighScores ) {
                            highScores.length = i;
                            break;
                        }
                        numScores++;
                        lastScore = highScores[i].score;
                    }
                }

                updateDoc(doc(db, "gameStats", String(game)), {highScores: highScores}).then(() => {
                    //console.log("game logged");
                }).catch( err => {
                    //console.log("can't create highscores");
                });
            }
        }
        scoreResponse(highScores);
    });
}

// called when user updates their name
export function updateHighScoreName(game, identifier, name, scoreResponse) {
    // fetch high scores
    let highScores = [];
    getGameResults(game, (stats) => {
        if (stats.highScores) {
            highScores = stats.highScores;
        }

        for ( let i=0 ; i<highScores.length ; i++ ) {
            if ( highScores[i].id == identifier ) {
                highScores[i].name = name;
                updateDoc(doc(db, "gameStats", String(game)), {highScores: highScores}).then(() => {
                    //console.log("game logged");
                }).catch( err => {
                    //console.log("can't create highscores");
                });
                scoreResponse(highScores);
                break;
            }
        }
    });
}

export function logGameResults(game, goalScore, score, duration) {
    logEvent(analytics, 'game_done', {game: game});

    let upd = {};
    if ( duration < 60 )
        upd["time0"] = increment(1);
    else if ( duration >= 60 && duration < 120 )
        upd["time1"] = increment(1);
    else if ( duration >= 120 && duration < 180 )
        upd["time2"] = increment(1);
    else if ( duration >= 180 && duration < 240 )
        upd["time3"] = increment(1);
    else if ( duration >= 240 )
        upd["time4"] = increment(1);
    upd["goal"+defs.scoreLevel(goalScore, score)] = increment(1);

    updateDoc(doc(db, "gameStats", String(game)), upd).then(() => {
        //console.log("game logged");
    }).catch( err => {
        // doesn't exist, so create
        setDoc(doc(db, "gameStats", String(game)), {}).then(() =>{
            //console.log("game logged, created");
            updateDoc(doc(db, "gameStats", String(game)), upd);
        });
    });

    // log same results to local storage
    let stats = ls.get("gamestats");
    if ( !stats ) {
        stats = {goal0: 0, goal1: 0, goal2: 0, goal3: 0};
    }
    stats["goal"+defs.scoreLevel(goalScore, score)]++;
    ls.set("gamestats", stats);
}

export function getGameResults(game, response) {
    const docRef = doc(db, "gameStats", String(game));
    const docSnap = getDocFromServer(docRef).then((docSnap) => {
        if (docSnap.exists()) {
            //console.log("Document data:", game, docSnap.data());
            response(docSnap.data());
        } else {
            // doc.data() will be undefined in this case
            //console.log("No such document!");
        }
    });
}

export function getPracticeGames(response) {
    const q = query(collection(db, "practicegames"));

    getDocs(q).then((querySnapshot) => {
        let games = [];
        querySnapshot.forEach((doc) => {
            games.push(doc.id);
        });
        response(games);
    });
}
