export const SCRIPT_TILE = 0;
export const SCRIPT_PLAY = 1;
export const SCRIPT_DELAY = 2;
export const SCRIPT_DONE = 3;
export const SCRIPT_UNDO = 4;

export const TUTORIAL_DIALOG = 10;
export const TUTORIAL_DONE = 11;

export const tutorialScript = 
    [
      {w:TUTORIAL_DIALOG, x:"0px", y:"250px", text:"Welcome to the Tiddle tutorial where you'll learn how to play Tiddle so you can compete with your friends to see who can get the highest score."},
      {w:TUTORIAL_DIALOG, x:"0px", y:"400px", text:"Tiles are played from here to the game grid above.",
            emphasis:["tile25", "tile26", "tile27", "tile28", "tile29", "tile30", "tile31", "tile32", "tile33", "tile34"]},

      {w:TUTORIAL_DIALOG, x:"0px", y:"160px", text:"Each word played will add to the score.", 
          emphasis:["playScore"]},
      {w:TUTORIAL_DIALOG, x:"0px", y:"160px", text:"These are the scores needed to earn medals",
          emphasis:["playScoreMedal"]},

      {w:TUTORIAL_DIALOG, x:"0px", y:"140px", text:"These squares will increase the score for tiles played on them, but only when first played.",
        emphasis:["tile5","tile13"]},
      
      {w:TUTORIAL_DIALOG, x:"0px", y:"200px", text:"I'll show you how to play the first word."},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_TILE, t:'a', g:11},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_TILE, t:'x', g:12},
      {w:SCRIPT_DELAY, ms:1000},
      {w:TUTORIAL_DIALOG, x:"0px", y:"330px", text:"Next, I'll press the Play button to validate and tally the score for the word.", emphasis:["playButton"]},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_PLAY},
      {w:SCRIPT_DELAY, ms:3000},

      {w:TUTORIAL_DIALOG, x:"0px", y:"200px", text:"I'll play another word to build up the score."},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_TILE, t:'t', g:10},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_PLAY},
      {w:SCRIPT_DELAY, ms:3000},

      {w:TUTORIAL_DIALOG, x:"0px", y:"200px", text:"And another word."},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_TILE, t:'i', g:13},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_PLAY},
      {w:SCRIPT_DELAY, ms:3000},

      {w:TUTORIAL_DIALOG, x:"0px", y:"200px", text:"Now, I'll play a vertical word."},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_TILE, t:'s', g:1},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_TILE, t:'m', g:6},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_TILE, t:'l', g:16},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_TILE, t:'l', g:21},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_PLAY},
      {w:SCRIPT_DELAY, ms:3000},

      {w:TUTORIAL_DIALOG, x:"0px", y:"200px", text:"Oops, I could have earned more points by building that word with 2 plays.  I'll press the Undo button to backup one move.",
            emphasis:["undoButton"]},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_UNDO},
      {w:SCRIPT_DELAY, ms:3000},

      {w:TUTORIAL_DIALOG, x:"0px", y:"200px", text:"Building words lets me score more points than simply playing a single long word."},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_TILE, t:'m', g:6},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_TILE, t:'l', g:16},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_TILE, t:'l', g:21},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_PLAY},
      {w:SCRIPT_DELAY, ms:3000},

      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_TILE, t:'s', g:1},
      {w:SCRIPT_PLAY},
      {w:SCRIPT_DELAY, ms:3000},

      {w:TUTORIAL_DIALOG, x:"0px", y:"420px", text:"I look for sweet spots like this to score multiple words with one play.", emphasis:["tile5", "tile10", "tile15"]},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_TILE, t:'a', g:5},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_TILE, t:'e', g:15},
      {w:SCRIPT_DELAY, ms:1000},
      {w:SCRIPT_PLAY},
      {w:SCRIPT_DELAY, ms:3000},


      {w:TUTORIAL_DIALOG, x:"0px", y:"330px", text:"I've gone as far as I can with this puzzle, so I press the Done button to complete the game. Then I can view my stats and share my score with friends.",
            emphasis:["doneButton"]},
      {w:SCRIPT_DELAY, ms:1000},
      {w:TUTORIAL_DONE},
      {w:TUTORIAL_DIALOG, x:"0px", y:"330px", text:"After completing a game I can watch how a Gold Medal game was played.  And, if I clicked on a game that someone shared, I could watch that one too."},
      {w:TUTORIAL_DIALOG, x:"0px", y:"200px", text:"The tutorial is finished.  You can see it again from the Instructions option in the menu bar.  There's a new Tiddle for you to enjoy every day. Hope you have fun playing!"},
      {w:SCRIPT_DONE},
    ];