import {storage} from '../firebase';
import {ref, getBytes, getBlob} from "firebase/storage";
export var dict = {};
loadDictionary();

async function loadDictionary() {
    //console.log("loadDictionary");
//    const gsReference = ref(storage, "gs://tiddle-a2d48.appspot.com/dict/testdict.txt");
    //let dictionary = "sowpods.txt";
    //let dictionary = "enable1.txt";
    //let dictionary = "en1_five.txt";
    let dictionary = "uber_five.txt";
    const qp = new URLSearchParams(window.location.search);
    if ( qp.get("dictionary") == "common" ) {
        dictionary = "popular.txt";
    }
    const gsReference = ref(storage, "gs://tiddle-a2d48.appspot.com/dict/" + dictionary);
    let blob = await getBlob(gsReference);
    let text = await blob.text();
    let lines = text.split('\n');
    for ( let i=0 ; i<lines.length ; i++ ) {
        dict[lines[i]] = true;
    }
}

