import { createSlice } from '@reduxjs/toolkit'
import {gridDimensions, pileSize, gridSize} from '../util/definitions';
var ls = require('local-storage');

// https://redux.js.org/tutorials/fundamentals/part-8-modern-redux#writing-thunks

/*
Tiles definition
selected = index of selected tile or false if none
score - current score
goalScore - score to beat
game - the identifier for the current game
start - time when the game was started
moves - array of moves
tile array
  letter - character or false if none
  decoration - special grid config - double letter, etc - is a string where S = Start location
  word - true if tile is part of a correctly spelled word
  move - play with latest'move'

tile character - or false if tile spot is empty
*/

const TILE_STATE_VERSION = 2;

export const tileSlice = createSlice({
  name: 'tile',
  initialState: {
    version: TILE_STATE_VERSION,
    selected: -1,
    score: 0,
    goalScore: 0,
    game: 0,
    start: null,
    tiles: [],
    moves: [],
    undos: 0,
    gameover: false,
    fauxgame: false,
  },
  reducers: {
    setWholeState: (state, payload) => {
      if ( state.version == TILE_STATE_VERSION ) {
        state.tiles = payload.payload.tiles;
        state.score = payload.payload.score;
        state.selected = payload.payload.selected;
        state.game = payload.payload.game;
        state.goalScore = payload.payload.goalScore;
        state.start = payload.payload.start;
        state.moves = payload.payload.moves;
        state.undos = payload.payload.undos;
        state.gameover = payload.payload.gameover;
        state.fauxgame = payload.payload.fauxgame;
      }
    },
    setTile: (state, payload) => {
      state.tiles[payload.payload.index] = payload.payload.tile;
      if ( !state.fauxgame )
        ls.set("game", state);
    },
    setTiles: (state, payload) => {
      state.tiles = payload.payload;
      if ( !state.fauxgame )
        ls.set("game", state);
    },
    setSelected: (state, payload) => {
      state.selected = payload.payload;
      if ( !state.fauxgame )
        ls.set("game", state);
    },
    setScore: (state, payload) => {
      state.score = payload.payload;
      if ( !state.fauxgame )
        ls.set("game", state);
    },
    setGoalScore: (state, payload) => {
      state.goalScore = payload.payload;
      if ( !state.fauxgame )
        ls.set("game", state);
    },
    setGame: (state, payload) => {
      state.game = payload.payload;
      if ( !state.fauxgame )
        ls.set("game", state);
    },
    pushMove: (state, payload) => {
      state.moves.push(payload.payload);
      if ( !state.fauxgame )
        ls.set("game", state);
    },
    popMove: (state, payload) => {
      state.moves.pop(payload.payload);
      if ( !state.fauxgame )
        ls.set("game", state);
    },
    decrementUndos: (state, payload) => {
      state.undos -= payload.payload;
      if ( !state.fauxgame )
        ls.set("game", state);
    },
    setGameover: (state, payload) => {
      state.gameover = payload.payload;
      if ( !state.fauxgame )
        ls.set("game", state);
    },
    setFauxGame: (state, payload) => {
      state.fauxgame = payload.payload;
    }

  },
})
export const { pushMove, popMove, setTile, setTiles, setSelected, setScore, setWholeState, decrementUndos, setGameover, setFauxGame} = tileSlice.actions


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getTGame = (state) => state.tile;

// helper functions
export function getTile(tiles, index) {return tiles[index]}

export function moveTile(dispatch, tiles, source, destination) {
    // is the destination occupied?
    let keeper = tiles[destination].letter ? tiles[destination].letter : false;
    dispatch(setTile({index:destination, tile:{...tiles[destination], letter:tiles[source].letter}}));
    // clear source
    dispatch(setTile({index:source, tile:{...tiles[source], letter:false}}));

    // find a home for the displaced
    if ( keeper ) {
      // was the source in the pile?
      if ( source >= gridSize ) {
        dispatch(setTile({index:source, tile:{...tiles[source], letter:keeper}}));
      }
      else {
        // find a home in the pile
        for ( let i=gridSize ; i < gridSize + pileSize ; i++ ) {
          if ( !tiles[i].letter ) {
            dispatch(setTile({index:i, tile:{...tiles[i], letter:keeper}}));
            break;
          }
        }
      }
    }
}

export function selectTile(dispatch, tiles, oldSelection, newSelection) {
  // if selecting current tile then just unselect it
  if ( newSelection == oldSelection ) {
    dispatch(setSelected(-1));
  }
  // if old selection was in the pile and new selection was in the pile then just select the new one if it's on a letter
  else if (oldSelection >= gridSize && newSelection >= gridSize && tiles[newSelection].letter ) {
    dispatch(setSelected(newSelection));
  }
  // if old selection was on the grid and new one is in the pile then remove selection and move tile
  else if ( oldSelection < gridSize && oldSelection != -1 && newSelection >= gridSize ) {
    moveTile(dispatch, tiles, oldSelection, newSelection);
    dispatch(setSelected(-1));
  }
  // if there is a previously selected tile then move it here
  else if ( oldSelection != -1 ) {
    moveTile(dispatch, tiles, oldSelection, newSelection);
    dispatch(setSelected(-1));
  }
  // if selecting an empty grid or pile square and no selection then ignore
  else if ( !tiles[newSelection].letter ) {
    // ignore
  }
  // if no tile selected then just select
  else if ( oldSelection == -1 ) {
    dispatch(setSelected(newSelection));
  }
}

export function sendTileToPile(dispatch, tiles, source) {
  // find a home in the pile
  for ( let i=gridSize ; i < gridSize + pileSize ; i++ ) {
    if ( !tiles[i].letter ) {
      moveTile(dispatch, tiles, source, i);
      break;
    }
  }
}

export function checkStateVersion(state) {
  return state.version == TILE_STATE_VERSION;
}

export default tileSlice.reducer
