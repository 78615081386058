export const currentBuild = "1.0.1";
export const gridDimensions = 5;
export const gridSize = (gridDimensions * gridDimensions);
export const pileSize = 10;
export const startLocation = 12;
export const gridWidth = 60;

export const NUM_UNDOS = 5;

export const TUTORIAL_GAME = "10";

export const STATSSHARE_GAMEOVER = 1;
export const STATSSHARE_TITLEBAR = 2;

//export const TODAY_MESSAGE = "Undo will now sweep all unplayed tiles off the grid without undoing the last move.  Choosing Undo again will undo the last move.  Handy for cleaning up the grid after exploring options.";
export const TODAY_MESSAGE = "";
export const TODAY_MESSAGE_NUMBER = "3";
export const TODAY_ERROR = "todayerror";    // the name of the popup

export const SCRIPT_GOLD = 1;
export const SCRIPT_SHARED = 2;
export const SCRIPT_HIGHSCORE = 3;

export const LOSER_LEVEL = 0;
export const BRONZE_LEVEL = 1;
export const SILVER_LEVEL = 2;
export const GOLD_LEVEL = 3;

const GOLD_PCT = 1.0;
const SILVER_PCT = 0.75;
const BRONZE_PCT = 0.65;
// 0 - loss
// 1 - bronze, 75%
// 2 - silver, 85%
// 3 - gold, 100%
export function scoreLevel(goalScore, score) {
  if ( score >= Math.floor(goalScore * GOLD_PCT) )
    return GOLD_LEVEL;
  if ( score >= Math.floor(goalScore * SILVER_PCT) )
    return SILVER_LEVEL;
  if ( score >= Math.floor(goalScore * BRONZE_PCT) )
    return BRONZE_LEVEL;
  return LOSER_LEVEL;
}

export function getScoreLevelScore(goalScore, level) {
  switch ( level ) {
    case GOLD_LEVEL:
      return Math.floor(GOLD_PCT * goalScore);
    case SILVER_LEVEL:
      return Math.floor(SILVER_PCT * goalScore);
    case BRONZE_LEVEL:
      return Math.floor(BRONZE_PCT * goalScore);
  }
  return -1;
}

export const letterScores = {
    'a': 1,
    'b': 3,
    'c': 3,
    'd': 2,
    'e': 1,
    'f': 4,
    'g': 2,
    'h': 4,
    'i': 1,
    'j': 8,
    'k': 5,
    'l': 1,
    'm': 3,
    'n': 1,
    'o': 1,
    'p': 3,
    'q': 10,
    'r': 1,
    's': 1,
    't': 1,
    'u': 1,
    'v': 4,
    'w': 4,
    'x': 8,
    'y': 4,
    'z': 10,
  };

/*
const extraLightGrayColor = rgb( 236, 236, 236)
const lightGrayColor = rgb( 222, 222, 222)
const mediumLightGrayColor = rgb(193, 193, 193)
const mediumGrayColor = rgb( 177, 177, 177)
const darkGrayColor = rgb( 149, 149, 149)
const extraDarkGrayColor = rgb( 106, 106, 106)
const darkestGrayColor = rgb( 78, 78, 78)

const blueColor = rgb( 120, 191, 206)
const mediumBlueColor = rgb(80, 151, 166)
const darkBlueColor = rgb( 100, 171, 186)
const nightBlueColor = rgb(17,100, 117)
const lightBlueColor = rgb(170, 211, 220)
const grayColor = rgb( 236, 236, 236)
const redColor = rgb( 234, 83, 93)
const darkRedColor = rgb( 194, 43, 53)
const sageColor = rgb( 149, 178, 143)
const sageColorDarkened = rgb(124, 153, 118)
const darkSageColor = rgb(90, 104, 87)
const homeYellowColor = rgb( 247, 195, 95)
const yellowColor = rgb( 247, 195, 95)
const darkYellowColor = rgb( 222, 170, 70)
const orangeColor = rgb(207, 155, 55)
*/