import * as defs from './definitions.mjs';
//import {dict} from './loadDict.mjs';
var dict = {};

function checkWord(word) {
    if ( word.length <= 1 )
      return false;
    if ( dict[word] ) {
      return true;
    }
    return false;
}
  
function setWordFlags(working, wordPos) {
    for ( let i=0 ; i<wordPos.length ; i++ ) {
      working[wordPos[i]].word = true;
    }
}
  
function islandCheck(tiles) {
    let trackingGrid = Array(defs.gridSize).fill(false);
    let islands = [];
    while ( true) {
      // first, find an occupied grid location that hasn't already been acocunted for
      let start = -1;
      for ( let i=0 ; i<defs.gridSize ; i++ ) {
        if ( tiles[i].letter && !trackingGrid[i] ) {
          start = i;
          break;
        }
      }
      // didn't find anything so we're done
      if ( start == -1 )
        break;
  
      // now trace this location until we've found all the connected tiles
      let backlog = [start];
      let island = [];
      while ( backlog.length > 0 ) {
        // check all the tiles around
        let target = backlog.pop();
        trackingGrid[target] = true;
        island.push(target);
  
        // left
        if ( target % defs.gridDimensions != 0 && tiles[target-1].letter && !trackingGrid[target-1] )
          backlog.push(target-1);
        // right
        if ( (target + 1) % defs.gridDimensions != 0 && tiles[target+1].letter && !trackingGrid[target+1] )
          backlog.push(target+1);
        // up
        if ( target > defs.gridDimensions && tiles[target-defs.gridDimensions].letter && !trackingGrid[target-defs.gridDimensions] )
          backlog.push(target-defs.gridDimensions);
        // down
        if ( target < defs.gridSize - defs.gridDimensions && tiles[target+defs.gridDimensions].letter && !trackingGrid[target+defs.gridDimensions] )
          backlog.push(target+defs.gridDimensions);
      }
      islands.push(island);
    }
  
    // if there's more than one then that's bad
    return islands.length == 1;
  }
  
  // returns the current score
  function findWords(tiles) {
  
    let working = new Array(defs.gridSize + defs.pileSize);
    for ( let i=0 ; i<defs.gridSize + defs.pileSize ; i++ ) {
      working[i] = {...tiles[i], word:false};
    }
  
    // if there are any disconnected letters then bail, all need to be connected
    if ( !islandCheck(tiles) ) 
      return false;

    // look for horizontal words
    let inWord = false;
    let word = "";
    let wordPos = [];
    for ( let i=0 ; i<defs.gridSize ; i++ ) {
      // new line?
      if ( i % defs.gridDimensions == 0 && inWord ) {
        if ( checkWord(word) ) {
          setWordFlags(working, wordPos);
        }
        else if ( word.length > 1)
          return false;
        inWord = false;
        word = "";
        wordPos = [];
      }
      // got a letter
      if ( tiles[i].letter ) {
          word = word + tiles[i].letter;
          wordPos.push(i);
          inWord = true;
      }
      else {
        // were we in a word?
        if ( inWord ) {
          if ( checkWord(word) ) {
            setWordFlags(working, wordPos);
          }
          else if ( word.length > 1)
            return false;
          inWord = false;
          word = "";
          wordPos = [];
        }
      }
    }
  
    // did we end on a word
    if ( inWord ) {
      if ( checkWord(word) ) {
        setWordFlags(working, wordPos);
      }
      else if ( word.length > 1)
        return false;
      inWord = false;
      word = "";
      wordPos = [];
    }
  
    // now look for vertical words
    for ( let i=0 ; i<defs.gridDimensions ; i++ ) {   // columns
      for ( let j=0 ; j<defs.gridDimensions ; j++ ) { // rows
        if ( tiles[j * defs.gridDimensions + i].letter ) {
          word = word + tiles[j * defs.gridDimensions + i].letter;
          wordPos.push(j * defs.gridDimensions + i);
          inWord = true;
        }
        else {
          // were we in a word?
          if ( inWord ) {
            if ( checkWord(word) ) {
              setWordFlags(working, wordPos);
            }
            else if ( word.length > 1)
              return false;
            inWord = false;
            word = "";
            wordPos = [];
          }
        }
      }
  
      // were we in a word?
      if ( inWord ) {
        if ( checkWord(word) ) {
          setWordFlags(working, wordPos);
        }
        else if ( word.length > 1)
          return false;
        inWord = false;
        word = "";
        wordPos = [];
      }
    }

    return working;
  }

export function isValidBoardState(tiles, move, dictionary, error) {
    dict = dictionary;

    // all new letters must be in a row or column
    let column = move[0] % defs.gridDimensions;
    let row = Math.floor(move[0] / defs.gridDimensions);
    let isRow = true, isColumn = true;
    for ( let i=1 ; i<move.length ; i++ ) {
      if ( Math.floor(move[i] / defs.gridDimensions) != row )
        isRow = false;
      if ( move[i] % defs.gridDimensions != column )
        isColumn = false;
    }
    if ( !isRow && !isColumn ) {
      // not a row nor a column
      error("row");
      return false;
    }
  
    // the new letters must all be connected, eg not part of disconnected words
    // bad example: t e t
    //              a   a   the a's played in a move are not legal
    let i = move[0];
    while ( i < move[move.length-1] ) {
      if ( !tiles[i].letter ) {
        error("row");
        return false;
      }
      if ( isRow )
        i++;
      else
        i += defs.gridDimensions;
    }
    
    // find words will update the flags on the tiles as a side effect
    let result = findWords(tiles);
    if ( !result ) {
      // not all valid words
      error("badwords");
      return false;
    }
  
    return result;
  }
  
  function scoreVerticalWord(tiles, start, move) {
    let score = 0;
    // start at first tile and go down
    let i = start;
    let letters = 0;
    while ( i < defs.gridSize && tiles[i].letter  ) {
      let s = defs.letterScores[tiles[i].letter];
      if ( move.includes(i) )
        s *= tiles[i].letterm;
      score += s;
      i += defs.gridDimensions;
      letters++;
    }
  
    // now go up
    i = start - defs.gridDimensions;
    while ( i >= 0 && tiles[i].letter  ) {
      let s = defs.letterScores[tiles[i].letter];
      if ( move.includes(i) )
        s *= tiles[i].letterm;
        score += s;
      i -= defs.gridDimensions;
      letters++;
    }
    return letters > 1 ? score:0;
  }
  
  function scoreHorizontalWord(tiles, start, move) {
    let score = 0;
    let i = start;
    let letters = 0;
    let row = Math.floor(i / defs.gridDimensions);
    // look right
    while ( Math.floor(i / defs.gridDimensions) == row && tiles[i].letter ) {
      let s = defs.letterScores[tiles[i].letter];
      if ( move.includes(i) )
        s *= tiles[i].letterm;
      score += s;
      i++;
      letters++;
    }
  
    // look left
    i = start-1;
    while ( i >= 0 && Math.floor(i / defs.gridDimensions) == row && tiles[i].letter ) {
      let s = defs.letterScores[tiles[i].letter];
      if ( move.includes(i) )
        s *= tiles[i].letterm;
      score += s;
      i--;
      letters++;
    }
    return letters > 1 ? score:0;
  }
  
  export function scoreMove(tiles, move, dictionary) {
    dict = dictionary;

    // horz or vert?
    let vert = move.length == 1 ? false : move[0] % defs.gridDimensions == move[1] % defs.gridDimensions;
    let score = 0;
  
    if ( vert ) {
      // add this word plus all the connecting tiles's scores vertically
      score += scoreVerticalWord(tiles, move[0], move);
  
      // add the scores of all horizontal words shooting off this one
      for ( let i=0 ; i<move.length ; i++ ) {
        score += scoreHorizontalWord(tiles, move[i], move);
      }
    }
    else {
      // add this word plus all the connecting tiles's scores horizontally
      score += scoreHorizontalWord(tiles, move[0], move);
  
      // add the scores of all vertical words shooting off this one
      for ( let i=0 ; i<move.length ; i++ ) {
        score += scoreVerticalWord(tiles, move[i], move);
      }
    }
    return score;
  }
  
